// JJ-108
import React, { useState, useEffect, useRef } from "react";
import { FaTimes } from "react-icons/fa";
import Hixenis from "../images/HIXENIS_NEW_W_P.png";
import BounceLoader from "react-spinners/BounceLoader";
import { register } from "../api/dataService";
import { useUser } from "../stores/UserContext";

function RegisterWindow({
  style,
  windowHeight,
  closeWindow,
  handleOpenAdminDashboard,
  handleOpenClientDashboard,
}) {
  const { user, updateUser } = useUser();
  const [loading, setLoadingState] = useState(false);
  const [loadingStateMessage, setLoadingStateMessage] = useState("");
  const [messageOverlayOpen, setMessageOverlayOpen] = useState(false);
  const [messageOverlayTitleText, setMessageOverlayTitleText] = useState("");
  const [messageOverlayTitleTextColor, setMessageOverlayTitleTextColor] =
    useState("#bbbbed");
  const [messageOverlayText, setMessageOverlayText] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmed, setPasswordConfirmed] = useState("");

  const handleRegisterClick = (e) => {
    setLoadingState(true);
    setLoadingStateMessage("Registering...");

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (!firstName || firstName.trim() === "") {
      setMessageOverlayOpen(true);
      setMessageOverlayTitleText("Invalid First Name");
      setMessageOverlayText("Please enter your first name.");
      setLoadingState(false);
      return;
    }

    if (!lastName || lastName.trim() === "") {
      setMessageOverlayOpen(true);
      setMessageOverlayTitleText("Invalid Last Name");
      setMessageOverlayText("Please enter your last name.");
      setLoadingState(false);
      return;
    }

    if (!email || !emailRegex.test(email)) {
      setMessageOverlayOpen(true);
      setMessageOverlayTitleText("Invalid Email");
      setMessageOverlayText("Please enter a valid email address.");
      setLoadingState(false);
      return;
    }

    if (!password || password.length < 8) {
      setMessageOverlayOpen(true);
      setMessageOverlayTitleText("Invalid Password");
      setMessageOverlayText("Password must be at least 8 characters long.");
      setLoadingState(false);
      return;
    }

    if (password !== passwordConfirmed) {
      setMessageOverlayOpen(true);
      setMessageOverlayTitleText("Passwords Do Not Match");
      setMessageOverlayText("Please ensure both password fields match.");
      setLoadingState(false);
      return;
    }

    register({
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
    })
      .then((response) => {
        if (response.status === "OK") {
          const userData = response.data;
          updateUser({
            id: userData.id,
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.email,
            isEmailVerified: userData.isEmailVerified,
            role: userData.role,
          });
          setLoadingState(false);
          setEmail("");
          setPassword("");
          console.log("userdata", userData);
          handleOpenClientDashboard({ user: userData });
        } else {
          setLoadingState(false);
          setMessageOverlayOpen(true);
          setMessageOverlayTitleText("Registration failed");
          setMessageOverlayTitleTextColor("red");
          setMessageOverlayText(`${response.detail}`);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingState(false);
        setMessageOverlayOpen(true);
        setMessageOverlayTitleText("Registration failed");
        setMessageOverlayTitleTextColor("red");
        setMessageOverlayText(`${error.detail}`);
      });
  };

  let styles = {
    mainContainer: {
      ...style,
      position: "absolute",
    },
    loadingOverlayMainContainer: {
      position: "absolute",
      zIndex: 12000,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "opacity 1s ease-in-out",
    },
    loadingOverlayInnerContainer: {
      padding: 30,
      backgroundColor: "rgba(44, 41, 63, 1)",
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      boxShadow: "0 0px 10px rgba(120, 0, 255, 1)",
      border: "1px solid #6E6EF4",
    },
    messageOverlayMainContainer: {
      position: "absolute",
      zIndex: 12000,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "opacity 1s ease-in-out",
    },
    messageOverlayInnerContainer: {
      width: 350,
      backgroundColor: "#191632",
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      boxShadow: "0 0px 15px rgba(0, 0, 0, .8)",
      border: "1px solid #6E6EF4",
      overflow: "hidden",
    },
    messageOverlayTitleContainer: {
      borderBottom: "0px solid #6E6EF4",
      width: "90%",
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 5,
    },
    messageOverlayTitleText: {
      letterSpacing: 2,
      marginTop: 5,
      fontSize: 18,
      color: messageOverlayTitleTextColor,
    },
    messageOverlayBodyTextContainer: {
      border: "0px solid red",
      width: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      padding: 16,
      paddingTop: 0,
    },
    messageOverlayBodyText: { marginTop: 5, textAlign: "center" },
    messageOverlayOKButtonContainer: {
      cursor: "pointer",
      borderTop: "1px solid #6E6EF4",
      width: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      padding: 16,
    },
    messageOverlayOKButtonText: {
      fontSize: 22,
      marginTop: 5,
      textAlign: "center",
    },
    registerWindowMainContainer: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    registerWindowInnerContainer: {
      border: "1.5px solid #6e6ef4",
      width: "100%",
      maxWidth: "375px",
      height: 700,
      borderRadius: 10,
      transition: "width 0.3s ease",
      boxShadow: "0 0px 15px rgba(120, 0, 255, .5)",
      position: "relative",
    },
    closeWindowContainer: { width: "100%", color: "white" },
    closeWindow: {
      marginLeft: 15,
      marginTop: 15,
      color: "#a6a6fc",
      cursor: "pointer",
    },
    logoContainer: {
      height: "15%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    logo: {
      maxWidth: "80%",
      maxHeight: "100%",
      objectFit: "contain",
    },
    headerContainer: {
      height: "15%",
      color: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 15,
      textAlign: "center",
      flexDirection: "column",
    },
    headerInnerContainer: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "#a6a6fc",
      paddingBottom: 0,
      marginBottom: 0,
      lineHeight: 1,
    },
    inputContainer: {
      height: "8%",
      color: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    input: {
      color: "black",
      width: "80%",
      height: 50,
      padding: 15,
      borderRadius: 5,
    },
    registerButton: {
      color: "white",
      backgroundColor: "#6e6ef4",
      width: "80%",
      height: 50,
      padding: 15,
      borderRadius: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    legal: {
      height: "15%",
      color: "#a6a6fc",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      padding: 15,
      fontSize: "0.65rem",
      lineHeight: "1.5",
      border: "0px solid red",
    },
  };

  return (
    <div style={styles.mainContainer}>
      {loading && (
        <div style={styles.loadingOverlayMainContainer}>
          <div style={styles.loadingOverlayInnerContainer}>
            <BounceLoader
              color={"#717DFA"}
              size={50}
              aria-label="Loading Spinner"
              data-testid="loader"
              speedMultiplier={1}
            />
            <p style={{ marginTop: 5 }}>{loadingStateMessage}</p>
          </div>
        </div>
      )}

      {messageOverlayOpen && (
        <div style={styles.messageOverlayMainContainer}>
          <div style={style.messageOverlayInnerContainer}>
            <div style={styles.messageOverlayTitleContainer}>
              <p className="bolden-gate" style={styles.messageOverlayTitleText}>
                {messageOverlayTitleText}
              </p>
            </div>
            <div style={styles.messageOverlayBodyTextContainer}>
              <p style={styles.messageOverlayBodyText}>{messageOverlayText}</p>
            </div>
            <div
              onClick={() => setMessageOverlayOpen(false)}
              style={styles.messageOverlayOKButtonContainer}
            >
              <p style={styles.messageOverlayOKButtonText}>{"OK"}</p>
            </div>
          </div>
        </div>
      )}

      <div style={styles.registerWindowMainContainer}>
        <div
          className="frosted-glass-container-blur-plus-plus-plus"
          style={styles.registerWindowInnerContainer}
        >
          <div style={styles.closeWindowContainer}>
            <FaTimes
              onClick={closeWindow}
              size={25}
              style={styles.closeWindow}
            />
          </div>

          <div style={styles.logoContainer}>
            <img src={Hixenis} alt="Logo" style={styles.logo} />
          </div>

          <div style={styles.headerContainer}>
            <div style={styles.headerInnerContainer}>Register Your Account</div>
            <div style={{ fontSize: "1rem" }}>
              You're one step closer to integrating your business with the power
              of AI.
            </div>
          </div>

          <div style={styles.inputContainer}>
            <input
              style={styles.input}
              type={"text"}
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>

          <div style={styles.inputContainer}>
            <input
              style={styles.input}
              type={"text"}
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>

          <div style={styles.inputContainer}>
            <input
              style={styles.input}
              type={"email"}
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div style={styles.inputContainer}>
            <input
              style={styles.input}
              type={"password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div style={styles.inputContainer}>
            <input
              style={styles.input}
              type={"password"}
              placeholder="Confirm Password"
              value={passwordConfirmed}
              onChange={(e) => setPasswordConfirmed(e.target.value)}
            />
          </div>

          <div style={{ ...styles.inputContainer, marginTop: 20 }}>
            <button
              className="bolden-gate"
              onClick={() => handleRegisterClick()}
              style={styles.registerButton}
            >
              Register
            </button>
          </div>
          <div style={styles.legal}>
            By registering, you agree to our Terms of Service and Privacy
            Policy. Your data may be used in accordance with our security
            guidelines. Please review our Cookie Policy for more information on
            data collection practices.
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterWindow;
