// JJ-108
import "../App.css";
import React from 'react';

function AnimatedTextButton({ onClick, theme, text, style, className }) {
  const handleClick = () => {
    onClick();
  };

  return (
    <div
     className={className}
      onClick={handleClick}
      style={{
        ...style,
        height: 45,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        borderRadius: 5,
        paddingRight: 15,
        paddingLeft: 15,
        width: '60%',
        alignSelf: 'center',
      }}
    >
      <div
        style={{
          userSelect: 'none',
          color: theme === 'dark' ? '#ffffff' : '#5a5ac9',
          fontSize: 18,
          width: '80%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {text}
      </div>

    </div>
  );
}

export default AnimatedTextButton;
