// JJ-108
import "../App.css"; // Ensure your CSS is linked
import React, { useState, useEffect } from "react";

function GlassContainer({ theme, children, style, disabled }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Adjust the threshold as needed

  const handleResize = () => {
    const isCurrentlyMobile = window.innerWidth < 768;
    setIsMobile(isCurrentlyMobile);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let styles = {
    desktopContainer: {
      ...style,
      border: "1px solid #262e59",
      display: "flex",
      width: "75%",
      justifyContent: "center",
      alignItems: "center",
    },
    mobileContainer: {
      border: disabled ? null : "1px solid #262e59",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginBottom: 15,
      borderRadius: 0,
      ...style,
    },
  };

  return (
    (!isMobile && (
      <div
        className="frosted-glass-container-blur-plus-plus"
        style={styles.desktopContainer}
      >
        {children}
      </div>
    )) || (
      <div
        className={disabled ? null : "frosted-glass-container-blur-plus-plus"}
        style={styles.mobileContainer}
      >
        <div style={{ width: "100%" }}>{children}</div>
      </div>
    )
  );
}

export default GlassContainer;
