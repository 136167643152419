// JJ-108
import React, { useState, useEffect, useRef } from "react";
import { FaTimes } from "react-icons/fa";
import Hixenis from "../images/HIXENIS_NEW_W_P.png";
import BounceLoader from "react-spinners/BounceLoader";
import { loginWithEmailAndPassword } from "../api/dataService";
import { useUser } from "../stores/UserContext";

function LoginWindow({
  style,
  windowHeight,
  closeWindow,
  handleOpenAdminDashboard,
  handleOpenClientDashboard,
  handleGoToPasswordResetScreen,
}) {
  const { user, updateUser } = useUser(); 
  const [loading, setLoadingState] = useState(false);
  const [loadingStateMessage, setLoadingStateMessage] = useState("");
  const [messageOverlayOpen, setMessageOverlayOpen] = useState(false);
  const [messageOverlayTitleText, setMessageOverlayTitleText] = useState("");
  const [messageOverlayTitleTextColor, setMessageOverlayTitleTextColor] =
    useState("#bbbbed");
  const [messageOverlayText, setMessageOverlayText] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleResetPasswordClick = () => {
    handleGoToPasswordResetScreen();
  };

  const handleLoginClick = (e) => {
    setLoadingState(true);
    setLoadingStateMessage("Logging in...");

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (!email || !emailRegex.test(email)) {
      setMessageOverlayOpen(true);
      setMessageOverlayTitleText("Invalid Email");
      setMessageOverlayText("Please enter a valid email address.");
      setLoadingState(false);
      return;
    }

    if (!password || password.length < 8) {
      setMessageOverlayOpen(true);
      setMessageOverlayTitleText("Invalid Password");
      setMessageOverlayText("Password must be at least 8 characters long.");
      setLoadingState(false);
      return;
    }

    loginWithEmailAndPassword({ email: email, password: password })
      .then((response) => {
        if (response.status === "OK") {
          const userData = response.data;

          updateUser({
            id: userData.id,
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.email,
            isEmailVerified: userData.isEmailVerified,
            role: userData.role,
          });
          setLoadingState(false);
          setEmail("");
          setPassword("");

          if (userData.role === "admin") {
            if (userData.id) {
              handleOpenAdminDashboard();
            }
          } else {
            if (userData.id) {
              handleOpenClientDashboard();
            }
          }
        } else {
          console.log(response);
          setLoadingState(false);
          setMessageOverlayOpen(true);
          setMessageOverlayTitleText("Login failed");
          setMessageOverlayTitleTextColor("red");
          setMessageOverlayText(`${response.detail}`);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingState(false);
        setMessageOverlayOpen(true);
        setMessageOverlayTitleText("Login failed");
        setMessageOverlayTitleTextColor("red");
        setMessageOverlayText(`${error.detail}`);
      });
  };

  let styles = {
    mainContainer: {
      ...style,
      position: "absolute",
    },
    loadingOverlayMainContainer: {
      position: "absolute",
      zIndex: 12000,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "opacity 1s ease-in-out", // Smooth transition,
    },
    loadingOverlayInnerContainer: {
      padding: 30,
      backgroundColor: "rgba(44, 41, 63, 1)",
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      boxShadow: "0 0px 10px rgba(120, 0, 255, 1)",
      border: "1px solid #6E6EF4",
    },
    messageOverlayMainContainer: {
      position: "absolute",
      zIndex: 12000,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "opacity 1s ease-in-out", // Smooth transition,
    },
    messageOverlayInnerContainer: {
      width: 350,
      backgroundColor: "#191632",
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      boxShadow: "0 0px 15px rgba(0, 0, 0, .8)",
      border: "1px solid #6E6EF4",
      overflow: "hidden",
    },
    messageOverlayTitleContainer: {
      borderBottom: "1px solid #6E6EF4",
      width: "90%",
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 5,
    },
    messageOverlayTitleText: {
      letterSpacing: 2,
      marginTop: 5,
      fontSize: 18,
      color: messageOverlayTitleTextColor,
    },
    messageOverlayBodyTextContainer: {
      border: "0px solid red",
      width: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      padding: 16,
      paddingTop: 0,
    },
    messageOverlayBodyText: { marginTop: 5, textAlign: "center" },
    messageOverlayOKButton: {
      cursor: "pointer",
      borderTop: "1px solid #6E6EF4",
      width: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      padding: 16,
    },
    messageOverlayOKText: { fontSize: 22, marginTop: 5, textAlign: "center" },
    loginMainContainer: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    loginInnerContainer: {
      border: "1.5px solid #6e6ef4",
      width: "100%",
      maxWidth: "375px",
      height: 600,
      borderRadius: 10,
      transition: "width 0.3s ease",
      boxShadow: "0 0px 15px rgba(120, 0, 255, .5)",
      position: "relative",
    },
    closeWindowContainer: { width: "100%", color: "white" },
    closeWindow: {
      marginLeft: 15,
      marginTop: 15,
      color: "#a6a6fc",
      cursor: "pointer",
    },
    logoContainer: {
      height: "15%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    logo: {
      maxWidth: "80%",
      maxHeight: "100%",
      objectFit: "contain",
    },
    headerContainer: {
      height: "15%",
      color: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 15,
      textAlign: "center",
      flexDirection: "column",
    },
    subheaderContainer: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "#a6a6fc",
      paddingBottom: 0,
      marginBottom: 0,
      lineHeight: 1,
    },
    emailInputContainer: {
      height: "10%",
      color: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    emailInput: {
      color: "black",
      width: "80%",
      height: 50,
      padding: 15,
      borderRadius: 5,
    },
    passwordInputContainer: {
      height: "10%",
      color: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    passwordInput: {
      color: "black",
      width: "80%",
      height: 50,
      padding: 15,
      borderRadius: 5,
    },
    loginButtonContainer: {
      height: "8%",
      color: "white",
      display: "flex",
      marginTop: 20,
      justifyContent: "center",
    },
    loginButton: {
      color: "white",
      backgroundColor: "#6e6ef4",
      width: "80%",
      height: 50,
      padding: 15,
      borderRadius: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    forgotPasswordContainer: {
      height: "10%",
      color: "#a6a6fc",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      marginTop: 20,
    },
    forgotPassword: {
      fontWeight: "bold",
      textDecoration: "underline",
      border: "none",
      backgroundColor: "transparent",
      color: "white",
    },
    legalContainer: {
      height: "15%",
      color: "#a6a6fc",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      padding: 15,
      fontSize: "0.65rem",
      lineHeight: "1.5",
    },
  };

  return (
    <div style={styles.mainContainer}>
      {loading && (
        <div style={styles.loadingOverlayMainContainer}>
          <div style={styles.loadingOverlayInnerContainer}>
            <BounceLoader
              color={"#717DFA"}
              size={50}
              aria-label="Loading Spinner"
              data-testid="loader"
              speedMultiplier={1}
            />
            <p style={{ marginTop: 5 }}>{loadingStateMessage}</p>
          </div>
        </div>
      )}

      {messageOverlayOpen && (
        <div style={styles.messageOverlayMainContainer}>
          <div style={styles.messageOverlayInnerContainer}>
            <div style={styles.messageOverlayTitleContainer}>
              <p className="bolden-gate" style={styles.messageOverlayTitleText}>
                {messageOverlayTitleText}
              </p>
            </div>
            <div style={style.messageOverlayBodyTextContainer}>
              <p style={styles.messageoverlayBodyText}>{messageOverlayText}</p>
            </div>
            <div
              onClick={() => setMessageOverlayOpen(false)}
              style={styles.messageOverlayOKButton}
            >
              <p style={styles.messageOverlayOKText}>{"OK"}</p>
            </div>
          </div>
        </div>
      )}

      <div style={styles.loginMainContainer}>
        <div
          className="frosted-glass-container-blur-plus-plus-plus"
          style={styles.loginInnerContainer}
        >
          <div style={styles.closeWindowContainer}>
            <FaTimes
              onClick={closeWindow}
              size={25}
              style={styles.closeWindow}
            />
          </div>

          <div style={styles.logoContainer}>
            <img src={Hixenis} alt="Logo" style={styles.logo} />
          </div>

          <div style={styles.headerContainer}>
            <div style={styles.subheaderContainer}>Sign Into Your Account</div>
            <div style={{ fontSize: "1rem" }}>
              Access your client dashboard and stay connected.
            </div>
          </div>

          <div style={styles.emailInputContainer}>
            <input
              style={styles.emailInput}
              type={"email"}
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div style={styles.passwordInputContainer}>
            <input
              style={styles.passwordInput}
              type={"password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div style={styles.loginButtonContainer}>
            <button
              className="bolden-gate"
              onClick={() => handleLoginClick()}
              style={styles.loginButton}
            >
              Log In
            </button>
          </div>
          <div style={styles.forgotPasswordContainer}>
            <div>Forgot your password?</div>
            <div>
              <button
                onClick={() => handleResetPasswordClick()}
                style={styles.forgotPassword}
              >
                Reset Password
              </button>
            </div>
          </div>
          <div style={styles.legalContainer}>
            By signing in, you agree to our Terms of Service and Privacy Policy.
            Your data may be used in accordance with our security guidelines.
            Please review our Cookie Policy for more information on data
            collection practices.
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginWindow;
