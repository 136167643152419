// JJ-108
import "../App.css"; // Ensure your CSS is linked
import React, { useState, useEffect } from "react";
import moment from "moment/moment";
import { FaReact } from "react-icons/fa";
import { FaPython } from "react-icons/fa";
import { FaJava } from "react-icons/fa";

function ProjectItem({
  theme,
  style,
  title,
  description,
  technologies,
  icon,
  sourceCodeAvailable,
  url,
  onClick,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1009);

  const handleResize = () => {
    const isCurrentlyMobile = window.innerWidth < 1009;
    setIsMobile(isCurrentlyMobile);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const techStyles = {
    react_js: {
      border: "2px solid #61DAFB",
      backgroundColor: "#23272D",
      color: "#61DAFB",
    },
    react_native: {
      border: "2px solid #61DAFB",
      backgroundColor: "#23272D",
      color: "#61DAFB",
    },
    node_js: {
      border: "2px solid #5FA04E",
      backgroundColor: "#23272D",
      color: "#5FA04E",
    },
    javascript: {
      border: "2px solid #F0DB4F",
      backgroundColor: "#323330",
      color: "#F0DB4F",
    },
    python: {
      border: "2px solid #306998",
      backgroundColor: "#FFE873",
      color: "#306998",
    },
    html: {
      border: "2px solid #E34F26",
      backgroundColor: "#F06529",
      color: "#E34F26",
    },
    css: {
      border: "2px solid white",
      backgroundColor: "#2965F1",
      color: "white",
    },
    php: {
      border: "2px solid #8993be",
      backgroundColor: "#4F5B93",
      color: "#8993be",
    },
    mongodb: {
      border: "2px solid #4DB33D",
      backgroundColor: "#23272D",
      color: "#4DB33D",
    },
    firebase: {
      border: "2px solid #FFCA28",
      backgroundColor: "#23272D",
      color: "#FFCA28",
    },
    stripe: {
      border: "2px solid #6772E5",
      backgroundColor: "#23272D",
      color: "#6772E5",
    },
    aws_s3: {
      border: "2px solid #FF9900",
      backgroundColor: "#23272D",
      color: "#FF9900",
    },
    digital_ocean: {
      border: "2px solid #0080FF",
      backgroundColor: "#23272D",
      color: "#0080FF",
    },
    nginx: {
      border: "2px solid #009639",
      backgroundColor: "#23272D",
      color: "#009639",
    },
    keystone: {
      border: "2px solid #3D7587",
      backgroundColor: "#23272D",
      color: "#3D7587",
    },
    twilio: {
      border: "2px solid #F22F46",
      backgroundColor: "#23272D",
      color: "#F22F46",
    },
    pm2: {
      border: "2px solid #2CA536",
      backgroundColor: "#23272D",
      color: "#2CA536",
    },
    websocket: {
      border: "2px solid #888888",
      backgroundColor: "#23272D",
      color: "#888888",
    },
    express_js: {
      border: "2px solid #303030",
      backgroundColor: "#23272D",
      color: "#303030",
    },
    gradle: {
      border: "2px solid #02303A",
      backgroundColor: "#23272D",
      color: "#02303A",
    },
    ruby: {
      border: "2px solid white",
      backgroundColor: "#B71302",
      color: "white",
    },
  };

  let styles = {
    mainContainer: {
      ...style,
      height: 260,
      width: 340,
      border: isHovered ? "1px solid #98FF98" : "1px solid #6e6ef4",
      borderRadius: 15,
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#120922",
      background: isHovered
        ? "linear-gradient(180deg, rgba(28,19,57,1) 0%, rgba(14,14,33,0.7) 52%, rgba(28,19,57,1) 94%)"
        : "linear-gradient(0deg, rgba(58,19,110,.7) 0%, rgba(14,14,33,0.7) 82%, rgba(28,19,57,1) 94%)",
      boxShadow: "0 4px 20px rgba(0, 0, 0, 0.5)",
      margin: 15,
      cursor: "pointer",
    },
    titleAndIcon: {
      height: "25%",
      padding: 15,
      display: "flex",
      flexDirection: "row",
    },
    titleContainer: {
      flex: 8,
      padding: 0,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    headerTitle: { paddingLeft: 15, fontSize: 22, color: "white" },
    iconContainer: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    descriptionContainerMain: {
      height: "35%",
      padding: 15,
      paddingTop: 0,
      display: "flex",
      flexDirection: "row",
      overflow: "hidden",
    },
    descriptionInnerContainer: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    descriptionText: {
      paddingLeft: 15,
      textAlign: "left",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 3,
      lineHeight: "1.5em",
      maxHeight: "5em",
      whiteSpace: "normal",
    },
    technologiesContainerMain: {
      fontSize: 13,
      height: "30%",
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      paddingLeft: 20,
      overflow: "visible",
    },
    technologiesListContainer: {
      width: "100%",
      padding: 5,
      flexWrap: "wrap",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    technologiesListItemsContainer: { display: "flex", flexWrap: "wrap" },
    technologiesLanguageContainer: {
      display: "inline-block",
      borderRadius: 15,
      padding: "3px 10px",
      margin: "5px",
      whiteSpace: "nowrap",
      textAlign: "left",
    },
    technologiesLanguageContainer2: {
      display: "inline-block",
      border: "2px solid white",
      borderRadius: 15,
      padding: "3px 10px",
      margin: "5px",
      whiteSpace: "nowrap",
      textAlign: "left",
      backgroundColor: "black",
      color: "white",
      boxShadow: `0 1px 10px white`,
    },
    technologiesLanguageContainer3: {
      display: "inline-block",
      border: "2px solid #6e6ef4",
      borderRadius: 15,
      padding: "3px 10px",
      margin: "5px",
      whiteSpace: "nowrap",
      textAlign: "left",
      backgroundColor: "black",
      color: "white",
      boxShadow: `0 1px 10px white`,
    },
    sourceCodeStatusContainer: {
      height: "17%",
      display: "flex",
      flexDirection: "row",
    },
    sourceCodeStatusInnerContainer: {
      width: "100%",
      padding: 15,
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-end",
    },
    sourceCodeStatus: {
      fontSize: 14,
      textAlign: "right",
      textOverflow: "ellipsis",
      color: sourceCodeAvailable ? "#07fc03" : "#fc5e03",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "100%",
      marginTop: "auto",
    },
    mainContainerMobile: {
      height: 280,
      width: "95%",
      border: isHovered ? "1px solid #98FF98" : "1px solid #6e6ef4",
      borderRadius: 15,
      ...style,
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#120922",
      background: isHovered
        ? "linear-gradient(180deg, rgba(28,19,57,1) 0%, rgba(14,14,33,0.7) 52%, rgba(28,19,57,1) 94%)"
        : "linear-gradient(0deg, rgba(58,19,130,.7) 0%, rgba(14,14,33,0.7) 82%, rgba(28,19,57,1) 94%)",
      boxShadow: "0 4px 20px rgba(0, 0, 0, 0.5)",
      margin: 15,
      cursor: "pointer",
      color: "white",
    },
    titleAndIconMobile: {
      height: "23%",
      padding: 15,
      display: "flex",
      flexDirection: "row",
    },
    titleContainerMobile: {
      flex: 8,
      padding: 0,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    titleMobile: { paddingLeft: 15, fontSize: 26 },
    iconContainerMobile: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    descriptionMainContainerMobile: {
      height: "30%",
      padding: 15,
      paddingTop: 0,
      display: "flex",
      flexDirection: "row",
      overflow: "hidden",
    },
    descriptionInnerContainerMobile: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    descriptionTextMobile: {
      paddingLeft: 15,
      textAlign: "left",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 3,
      lineHeight: "1.5em",
      maxHeight: "5em",
      whiteSpace: "normal",
    },
    technologiesMainContainerMobile: {
      fontSize: 13,
      height: "36%",
      overflow: "hidden",
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      paddingLeft: 20,
    },
    technologiesInnerContainerMobile: {
      width: "100%",
      padding: 5,
      flexWrap: "wrap",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    technologiesListItemOuterContainerMobile: {
      display: "flex",
      flexWrap: "wrap",
    },
    technologiesListItemMainContainerMobile: {
      display: "flex",
      borderRadius: 15,
      alignItems: "center",
      lineHeight: 3,
      justifyContent: "center",
      padding: "3px 10px",
      margin: 5,
    },
    techLanguageMobile: { border: "0px solid white", lineHeight: 2, margin: 0 },
    techLanguageMobile2: {
      display: "flex",
      border: "2px solid white",
      borderRadius: 15,
      backgroundColor: "black",
      color: "white",
      alignItems: "center",
      justifyContent: "center",
      padding: "3px 10px",
      margin: 5,
    },
    techLanguageText: { border: "0px solid white", lineHeight: 2, margin: 0 },
    techLanguageMobile3: {
      display: "flex",
      border: "2px solid #6e6ef4",
      borderRadius: 15,
      backgroundColor: "black",
      color: "white",
      boxShadow: `0 1px 10px white`,
      alignItems: "center",
      justifyContent: "center",
      padding: "3px 10px",
      margin: 5,
    },
    techLanguageMore: { border: "0px solid white", lineHeight: 2, margin: 0 },
    sourceCodeStatusMobile: {
      height: "15%",
      display: "flex",
      flexDirection: "row",
    },
    sourceCodeStatusInnerContainerMobile: {
      width: "100%",
      padding: 15,
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-end",
    },
    sourceCodeAvailableMobile: {
      fontSize: 14,
      textAlign: "right",
      textOverflow: "ellipsis",
      color: sourceCodeAvailable ? "#07fc03" : "#fc5e03",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "100%",
    },
  };

  return (
    (!isMobile === true && (
      <div
        className={`non-highlightable ${isHovered ? "hovered" : ""}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => (onClick ? onClick() : null)}
        style={styles.mainContainer}
      >
        <div style={styles.titleAndIcon}>
          <div style={styles.titleContainer}>
            <h2 className="bolden-gate" style={styles.headerTitle}>
              {title}
            </h2>
          </div>
          <div style={styles.iconContainer}>
            {(icon === "react_native" && (
              <FaReact
                color={theme === "dark" ? "#ccccff" : "#5a5ac9"}
                size={40}
              />
            )) ||
              (icon === "react" && (
                <FaReact
                  color={theme === "dark" ? "#ccccff" : "#5a5ac9"}
                  size={40}
                />
              )) ||
              (icon === "python" && (
                <FaPython
                  color={theme === "dark" ? "#ccccff" : "#5a5ac9"}
                  size={40}
                />
              )) ||
              (icon === "java" && (
                <FaJava
                  color={theme === "dark" ? "#ccccff" : "#5a5ac9"}
                  size={40}
                />
              ))}
          </div>
        </div>

        <div style={styles.descriptionContainerMain}>
          <div style={styles.descriptionInnerContainer}>
            <p style={styles.descriptionText}>{description}</p>
          </div>
        </div>
        <div style={styles.technologiesContainerMain}>
          <div style={styles.technologiesListContainer}>
            {technologies && technologies.length > 0 && (
              <div style={styles.technologiesListItemsContainer}>
                {technologies.slice(0, 4).map((tech, index) => {
                  const techStyle = techStyles[tech.value];
                  const shadowColor = techStyle ? techStyle.color : "white";
                  return techStyle ? (
                    <div
                      key={index}
                      className="bolden-gate"
                      style={{
                        style,
                        ...styles.technologiesLanguageContainer,
                        border: techStyle.border,
                        backgroundColor: techStyle.backgroundColor,
                        color: techStyle.color,
                        boxShadow: `0 1px 10px ${shadowColor}, 0 2px 10px rgba(0, 0, 0, 0.3)`,
                      }}
                    >
                      {tech.language}
                    </div>
                  ) : (
                    <div
                      key={index}
                      className="bolden-gate"
                      style={styles.technologiesLanguageContainer2}
                    >
                      {tech.language}
                    </div>
                  );
                })}
                {technologies.length > 4 && (
                  <div
                    className="bolden-gate"
                    style={styles.technologiesLanguageContainer3}
                  >
                    + {technologies.length - 4} more
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div style={styles.sourceCodeStatusContainer}>
          <div style={styles.sourceCodeStatusInnerContainer}>
            <p style={styles.sourceCodeStatus}>
              {sourceCodeAvailable ? (
                <span className="bolden-gate" style={{ color: "#07fc03" }}>
                  {"Source Code: Available"}
                </span>
              ) : (
                <span className="bolden-gate" style={{ color: "#fc5e03" }}>
                  {"Source Code: Proprietary"}
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
    )) || (
      <div
        className={`non-highlightable ${isHovered ? "hovered" : ""}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => (onClick ? onClick() : null)}
        style={styles.mainContainerMobile}
      >
        <div style={styles.titleAndIconMobile}>
          <div style={styles.titleContainerMobile}>
            <h1 className="bolden-gate" style={styles.titleMobile}>
              {title}
            </h1>
          </div>
          <div style={styles.iconContainerMobile}>
            {(icon === "react_native" && (
              <FaReact
                color={theme === "dark" ? "#ccccff" : "#5a5ac9"}
                size={40}
              />
            )) ||
              (icon === "react" && (
                <FaReact
                  color={theme === "dark" ? "#ccccff" : "#5a5ac9"}
                  size={40}
                />
              )) ||
              (icon === "python" && (
                <FaPython
                  color={theme === "dark" ? "#ccccff" : "#5a5ac9"}
                  size={40}
                />
              )) ||
              (icon === "java" && (
                <FaJava
                  color={theme === "dark" ? "#ccccff" : "#5a5ac9"}
                  size={40}
                />
              ))}
          </div>
        </div>

        <div style={styles.descriptionMainContainerMobile}>
          <div style={styles.descriptionInnerContainerMobile}>
            <p style={styles.descriptionTextMobile}>{description}</p>
          </div>
        </div>

        <div style={styles.technologiesMainContainerMobile}>
          <div style={styles.technologiesInnerContainerMobile}>
            {technologies && technologies.length > 0 && (
              <div style={styles.technologiesListItemOuterContainerMobile}>
                {technologies.slice(0, 3).map((tech, index) => {
                  const techStyle = techStyles[tech.value];
                  const shadowColor = techStyle ? techStyle.color : "white";
                  return techStyle ? (
                    <div
                      key={index}
                      style={{
                        ...styles.technologiesListItemMainContainerMobile,
                        border: techStyle.border,
                        backgroundColor: techStyle.backgroundColor,
                        color: techStyle.color,
                        boxShadow: `0 1px 10px ${shadowColor}, 0 2px 10px rgba(0, 0, 0, 0.3)`,
                      }}
                    >
                      <p
                        className="bolden-gate"
                        style={styles.techLanguageMobile}
                      >
                        {tech.language}
                      </p>
                    </div>
                  ) : (
                    <div
                      key={index}
                      className="bolden-gate"
                      style={{
                        ...styles.techLanguageMobile2,
                        boxShadow: `0 1px 10px ${shadowColor}, 0 2px 10px rgba(0, 0, 0, 0.3)`,
                      }}
                    >
                      <p
                        className="bolden-gate"
                        style={styles.techLanguageText}
                      >
                        {tech.language}
                      </p>
                    </div>
                  );
                })}
                {technologies.length > 4 && (
                  <div
                    className="bolden-gate"
                    style={styles.techLanguageMobile3}
                  >
                    <p className="bolden-gate" style={styles.techLanguageMore}>
                      + {technologies.length - 4} more
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div style={styles.sourceCodeStatusMobile}>
          <div style={styles.sourceCodeStatusInnerContainerMobile}>
            <p style={styles.sourceCodeAvailableMobile}>
              {sourceCodeAvailable ? (
                <span className="bolden-gate" style={{ color: "#07fc03" }}>
                  {"Source Code: Available"}
                </span>
              ) : (
                <span className="bolden-gate" style={{ color: "#fc5e03" }}>
                  {"Source Code: Proprietary"}
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
    )
  );
}

export default ProjectItem;
