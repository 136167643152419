// JJ-108
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RegisterWindow from "../components/RegisterWindow";

function LoginScreen() {
  const location = useLocation();
  const props = location.state;
  const [isMobile, setIsMobile] = useState(props.isMobile);

  const closeLoginWindow = () => {
    navigate(-1);
  };

  const handleOpenAdminDashboard = (userData, navigate) => {
    navigate("/AdminDashboard", { state: { data: userData } });
  };
  const handleOpenClientDashboard = (userData, navigate) => {
    //
    navigate("/ClientDashboard", { state: { data: userData } });
  };

  const navigate = useNavigate();

  return (
    <div style={styles.container}>
      <div style={styles.coloredBackground} />
      <RegisterWindow
        style={{}}
        handleOpenAdminDashboard={(userData) =>
          handleOpenAdminDashboard(userData, navigate)
        }
        handleOpenClientDashboard={(userData) =>
          handleOpenClientDashboard(userData, navigate)
        }
        isMobile={isMobile}
        windowHeight={window.innerHeight}
        closeWindow={() => closeLoginWindow()}
      />
    </div>
  );
}
let styles = {
  coloredBackground: {
    backgroundColor: "#090714",
    width: "100%",
    height: "100vh",
    position: "absolute",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    minHeight: "100vh",
    color: "white",
  },
};
export default LoginScreen;
