import "../App.css";
import React from "react";
import moment from "moment";

function ProductSpotlight({selectedSubscription, selectedProduct, handleCheckoutProduct, }) {
  let styles = {
    spotlightMain: {
      display: "flex",
      borderRadius: 5,
      flexDirection: "column",
      width: "59.7%",
      backgroundColor: "#1c1538",
      border: "1px solid #7484F9",
      padding: 15,
      height: "100%",
    },
    spotlightContainer1: { flexDirection: "column", width: "100%" },
    spotlightTitle: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    spotlightHeader: {
      color: "white",
      fontSize: 18,
      padding: 10,
      paddingTop: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "flex",
    },
    spotlightContentContainerMain: {
      textAlign: "center",
      width: "100%",
      alignItems: selectedSubscription ? "flex-start" : "center",
      display: "flex",
      justifyContent: "center",
    },
    selectedProductContainer: {
      userSelect: "none",
      textAlign: "center",
      width: "100%",
      height: "100%",
      minHeight: 560,
      border: "1px solid #7484F9",
      display: "flex",
      justifyContent: "flex-start",
      backgroundColor: "#281e51",
      borderRadius: 5,
      flexDirection: "column",
      marginTop: 5,
      marginBottom: "auto",
    },
    selectedProductInnerContainer: {
      width: "100%",
      flexDirection: "row",
      display: "flex",
    },
    selectedProductImageContainer: {
      width: "25%",
      border: "0px solid yellow",
      padding: 10,
    },
    selectedProductThumnbnail: {
      maxHeight: "100%",
      objectFit: "contain",
      borderRadius: 5,
      border: "2px solid #3C4684",
    },
    selectedProductInfo: {
      border: "0px solid yellow",
      height: "100%",
      width: "100%",
      display: "flex",
      marginTop: 10,
      marginRight: 10,
      flexDirection: "column",
    },
    selectedProductTitleAndGetButton: {
      height: "100%",
      width: "100%",
      flexDirection: "row",
      display: "flex",
    },
    selectedProductTitleContainer: {
      width: "75%",
      display: "flex",
      justifyContent: "flex-start",
    },
    selectedProductGetButton: {
      width: "25%",
      display: "flex",
      justifyContent: "flex-start",
    },
    selectedProductGetButtonInner: {
      cursor: "pointer",
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      backgroundColor: "green",
      borderRadius: 5,
    },
    selectedProductPrice: {
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textAlign: "left",
      color: "#2AD640",
      fontSize: 14,
    },
    selectedProductDescription: {
      height: "100%",
      width: "100%",
      flexDirection: "row",
      display: "flex",
    },
    selectedProductDescriptionInner: {
      border: "0px solid red",
      width: "75%",
      display: "flex",
      justifyContent: "flex-start",
    },
    selectedProductDescriptionText: {
      fontSize: 16,
      textAlign: "left",
      color: "#C6C6F2",
    },
  };

  return (
    <div style={styles.spotlightMain}>
      {/*  */}
      <div style={styles.spotlightContainer1}>
        <div style={styles.spotlightTitle}>
          <div style={{ borderBottom: "1px solid #34227c", flex: 1 }}>
            <h2 style={styles.spotlightHeader} className="bolden-gate">
              Spotlight
            </h2>
          </div>
        </div>
      </div>
      <div style={styles.spotlightContentContainerMain}>
        {(selectedProduct && (
          <div style={styles.selectedProductContainer}>
            <div style={styles.selectedProductInnerContainer}>
              <div style={styles.selectedProductImageContainer}>
                <img
                  src={selectedProduct.iconThumbnail.url}
                  alt={selectedProduct.iconThumbnail.alt}
                  style={styles.selectedProductThumnbnail}
                />
              </div>

              <div style={styles.selectedProductInfo}>
                <div style={styles.selectedProductTitleAndGetButton}>
                  <div style={styles.selectedProductTitleContainer}>
                    <p className="bolden-gate" style={{ fontSize: 22 }}>
                      {selectedProduct.name}
                    </p>
                  </div>

                  {/* <div style={{width: '10%'}} /> */}

                  <div style={styles.selectedProductGetButton}>
                    <div
                      onClick={() =>
                        handleCheckoutProduct(
                          selectedProduct.stripePriceId,
                          selectedProduct.stripeProductId
                        )
                      }
                      style={styles.selectedProductGetButtonInner}
                    >
                      <p className="bolden-gate" style={{ fontSize: 14 }}>
                        GET
                      </p>
                    </div>
                  </div>
                </div>

                <p className="bolden-gate" style={styles.selectedProductPrice}>
                  {`Price: $${selectedProduct.subscriptionPrice / 100} CAD ${selectedProduct.billingFrequency}`}
                </p>

                <div style={styles.selectedProductDescription}>
                  <div style={styles.selectedProductDescriptionInner}>
                    <p style={styles.selectedProductDescriptionText}>
                      {selectedProduct.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )) ||
          "No product selected."}
      </div>
    </div>
  );
}

export default ProductSpotlight;
