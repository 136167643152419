// JJ-108
import "../App.css";
import { useState, useEffect, Fragment } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "../fonts/fonts.css";
import NavBar from "../components/NavBar";
import BasicNewsArticle from "../components/BasicNewsArticle";
import GlassContainer from "../components/GlassContainer";
import ProjectItem from "../components/ProjectItem";
import Footer from "../components/Footer";
import LoginWindow from "../components/LoginWindow";
import { FaRegCheckCircle } from "react-icons/fa";
import BounceLoader from "react-spinners/BounceLoader";

import {
  submitContactForm,
  fetchNewsArticles,
  fetchPortfolioProjects,
} from "../api/dataService";
import { StayCurrentLandscapeSharp, StyleSharp } from "@mui/icons-material";

function Home() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [theme, setTheme] = useState("dark");
  const [newsArticles, setNewsArticles] = useState([]);
  const [portfolioProjects, setPortfolioProjects] = useState([]);
  const [contactFormLoading, setContactFormLoading] = useState(false);
  const [contactFormIndicatorColor, setContactFormIndicatorColor] =
    useState("#6e6ef4");
  const [contactFormSubmitted, setContactFormSubmitted] = useState(false);
  const [contactFormHasErrors, setContactFormHasErrors] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    handleFetchNewsArticles();
    handleFetchPortfolioProjects();
  }, []);

  const handleResize = () => {
    const isCurrentlyMobile = window.innerWidth < 768;
    setIsMobile(isCurrentlyMobile);
  };

  const handleMoveToComponent = (value) => {
    const sectionIds = ["home", "projects", "about", "contact"];
    const targetSection = document.getElementById(sectionIds[value]);

    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleFetchNewsArticles = () => {
    fetchNewsArticles()
      .then((response) => {
        if (response.status === "OK") {
          setNewsArticles(response.data);
        } else {
          console.log(`Error getting news articles:, ${response.status}`);
        }
      })
      .catch((error) => {
        console.log(`Error getting news articles:, ${error}`);
      });
  };

  const handleFetchPortfolioProjects = () => {
    fetchPortfolioProjects()
      .then((response) => {
        if (response.status === "OK") {
          setPortfolioProjects(response.data);
        } else {
          console.log(`Error getting portfolio projects:, ${response.status}`);
        }
      })
      .catch((error) => {
        console.log(`Error getting portfolio projects:, ${error}`);
      });
  };

  const handleToggleTheme = () => {
    if (theme === "dark") {
      setTheme("light");
    } else {
      setTheme("dark");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleGoToLoginScreen = (navigate) => {
    navigate("/LoginScreen", { state: { isMobile: isMobile } });
  };

  const handleGoToRegisterScreen = (navigate) => {
    navigate("/RegisterScreen", { state: { isMobile: isMobile } });
  };

  const formatUrl = (url) => {
    if (!url.startsWith("http")) {
      return `https://${url}`;
    }
    return url;
  };

  const handleSubmit = (e) => {
    setContactFormLoading(true);
    e.preventDefault();

    submitContactForm(formData)
      .then((response) => {
        setTimeout(() => {
          if (response.status === "OK") {
            setContactFormLoading(false);
            setContactFormSubmitted(true);
            setFormData({ name: "", email: "", message: "" });
          } else {
            setContactFormHasErrors(true);
            setContactFormLoading(false);
          }
        }, 1000);
      })
      .catch((error) => {
        setContactFormHasErrors(true);
        setContactFormLoading(false);
        console.log(`error, ${error}`);
      });
  };

  const navigate = useNavigate();

  let styles = {
    mainAppContainer: {
      height: "100%",
      width: "100%",
      backgroundColor: "#090714",
    },
    registerAndLoginBar: {
      border: "0px solid yellow",
      width: "100%",
      padding: 50,
      position: "absolute",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    registerButton: {
      zIndex: 12000,
      cursor: "pointer",
      display: "flex",
      borderRadius: 5,
      alignSelf: "center",
      width: 120,
      height: 40,
      alignItems: "center",
      justifyContent: "center",
    },
    registerText: {
      color: "white",
    },
    loginButton: {
      zIndex: 12000,
      cursor: "pointer",
      display: "flex",
      borderRadius: 5,
      alignSelf: "center",
      width: 120,
      height: 40,
      backgroundColor: "#6e6ef4",
      alignItems: "center",
      justifyContent: "center",
    },
    loginText: {
      color: "white",
    },
    linearGradientMain: {
      height: 500,
      width: "100%",
      position: "absolute",
      background:
        "linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(14, 14, 33, 0.6) 70%, rgba(25, 23, 53, 1) 100%)",
      zIndex: -1,
    },
    primaryTitleBar: {
      marginTop: 40,
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      pointerEvents: "none",
      zIndex: 2,
      border: "0px solid GREEN",
    },
    secondaryTitleBar: {
      border: "0px solid red",
      display: "flex",
      flexDirection: "row",
      width: "80%",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    mainTitlePrimary: {
      fontSize: 40,
      userSelect: "none",
    },
    mainTitleSecondary: {
      fontSize: 40,

      userSelect: "none",
    },
    subtitleOuterContainer: {
      border: "0px solid red",
      display: "flex",
      flexDirection: "row",
      width: "80%",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    subtitleInnerContainer: {
      lineHeight: 0,
      marginRight: "auto",
      userSelect: "none",
      letterSpacing: 7.3,
    },
    primaryTitleBarMobile: {
      marginTop: 40,
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      pointerEvents: "none",
      zIndex: 2,
    },
    secondaryTitleBarMobile: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    mainTitlePrimaryMobile: {
      fontSize: 40,
      userSelect: "none",
    },
    mainTitleSecondaryMobile: {
      fontSize: 40,
      userSelect: "none",
    },
    subtitleContainerMobile: {
      border: "0px solid red",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    subtitleTextMobile: {
      lineHeight: 0,
      letterSpacing: 7.3,
    },
    projectsContainerMain: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      borderWidth: 0,
    },
    glassContainerProjects: {
      display: "flex",
      flexDirection: "column",
    },
    projectsHeaderContainer: {
      borderWidth: 0,
      width: "100%",
      flexDirection: "row",
      display: "flex",
      justifyContent: "center",
    },
    projectsHeaderSecondaryContainer: {
      width: "100%",
    },
    projectsTitle: {
      fontSize: 22,
      color: "#6e6ef4",
      userSelect: "none",
      textAlign: "left",
      paddingTop: 35,

      padding: "0px 30px",
    },
    projectsTitleMobile: {
      fontSize: 22,
      color: "#6e6ef4",
      userSelect: "none",
      textAlign: "left",
      paddingTop: 20,
      padding: "0px 100px",
    },
    projectsDescriptionTextContainer: {
      width: "100%",
      border: "0px solid red",
      paddingBottom: 20,
    },
    projectsDescriptionText: {
      padding: "0px 100px",
      color: "white",
      textAlign: "left",
    },
    projectsDescriptionTextMobile: {
      padding: "0px 30px",
      color: "white",
      textAlign: "left",
    },
    projectsContentContainer: {
      paddingLeft: 0,
      paddingRight: 0,
      borderWidth: 0,
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      flexWrap: "wrap",
    },
    articlesContainerMain: {
      marginTop: 10,
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      pointerEvents: "none",
      zIndex: 2,
    },
    articlesInnerContainer: {
      width: "100%",
      display: "flex",
      border: "0px solid white",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    contactContainerMain: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      marginTop: 0,
    },
    glassContainerContact: {
      padding: 20,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      paddingBottom: 50,
    },
    contactTitle: {
      fontSize: 22,
      padding: 30,
      paddingTop: 50,
      color: "white",
    },
    contactTitleMobile: {
      fontSize: 22,
      padding: 0,
      paddingTop: 20,
      color: "white",
    },
    contactForm: {
      width: "100%",
      border: "0px solid white",
      display: "flex",
      justifyContent: "center",
    },
    contactLoadingOverlayMain: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    contactLoadingText: {
      fontSize: 25,
      color: "white",
    },
    contactFormSubmittedContainerMain: {
      width: "100%",
      flexDirection: "column",
      border: "0px solid white",
      display: "flex",
      justifyContent: "center",
      height: 350,
      alignItems: "center",
    },
    checkCircleStyle: {
      color: "#03fc0b",
    },
    messageReceivedText: {
      fontSize: 25,

      color: "#03fc0b",
      paddingLeft: 30,
      paddingRight: 30,
    },
    outerContainerForm: {
      display: "flex",
      flexDirection: "column",
      width: "85%",
    },
    formInputTextName: {
      padding: 10,
      margin: 10,
      borderRadius: 5,
      outline: "none",
      fontSize: 16,
      color: "black",
    },
    formInputEmail: {
      padding: 10,
      margin: 10,
      borderRadius: 5,
      outline: "none",
      fontSize: 16,
      color: "black",
    },
    formInputMessage: {
      padding: 10,
      margin: 10,
      borderRadius: 5,
      outline: "none",
      fontSize: 16,
      color: "black",
    },
    formSubmitButton: {
      padding: 10,
      margin: 10,
      color: "white",
      border: "none",
      borderRadius: 5,
      cursor: "pointer",
      fontSize: 16,
      transition: "background-color 0.3s",
    },
    contactErrorText: {
      color: "red",
      fontSize: 20,
    },
    contactHeaderContainerMain: {
      width: "50%",
      border: "0px solid red",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      marginBottom: "auto",
    },
    contactTitle: {
      fontSize: 25,
      padding: !isMobile ? 30 : 0,
      paddingTop: !isMobile ? 50 : 20,
      color: "#6e6ef4",
      letterSpacing: 2,
    },
    contactSubtitle: {
      fontSize: 22,
      paddingLeft: !isMobile ? 35 : 0,
      color: "white",
      textAlign: "left",
    },
    contactJordan: {
      marginTop: 5,
      fontSize: 22,
      paddingLeft: !isMobile ? 35 : 0,
      color: "white",
      textAlign: "left",
    },
    glassContainerContactMobile: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      paddingBottom: 50,
    },
    contactTitleMobileSecondary: {
      fontSize: 25,
      padding: 0,
      paddingTop: 20,
      color: "white",
      letterSpacing: 10,
    },
    contactFormContainer: {
      width: "100%",
      border: "0px solid white",
      display: "flex",
      justifyContent: "center",
      height: contactFormLoading === true ? 350 : "auto",
    },
    contactFormLoadingMobile: {
      display: "flex",
      flexDirection: "column",
      border: "0px solid white",
      alignItems: "center",
      justifyContent: "center",
    },
    contactFormLoadingTextMobile: {
      fontSize: 25,
      padding: !isMobile ? 30 : 0,
      color: "white",
    },
    contactFormSubmittedMobile: {
      width: "100%",
      flexDirection: "column",
      border: "0px solid white",
      display: "flex",
      justifyContent: "center",
      height: 350,
      alignItems: "center",
    },
    contactFormCircleCheckMobile: {
      fontSize: 25,
      padding: !isMobile ? 15 : 0,
      color: "#03fc0b",
      paddingLeft: 30,
      paddingRight: 30,
    },
    contactFormInnerContainerMobile: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      maxWidth: 400,
    },
    contactFormInputNameMobile: {
      padding: 10,
      margin: 10,
      borderRadius: 5,
      outline: "none",
      fontSize: 16,
      color: "black",
    },
    contactFormInputEmailMobile: {
      padding: 10,
      margin: 10,
      borderRadius: 5,
      outline: "none",
      fontSize: 16,
      color: "black",
    },
    contactFormInputMessageMobile: {
      padding: 10,
      margin: 10,
      borderRadius: 5,
      outline: "none",
      fontSize: 16,
      color: "black",
    },
    contactFormSubmitButtonMobile: {
      padding: 10,
      margin: 10,
      backgroundColor: theme === "light" ? "#5a59c9" : "#6e6ef4",
      color: "white",
      border: "none",
      borderRadius: 5,
      cursor: "pointer",
      fontSize: 16,
      transition: "background-color 0.3s",
    },
    contactFormErrorsMobile: { color: "red", fontSize: 20 },
  };

  return (
    <div className="App" style={styles.mainAppContainer}>
      {!isMobile && (
        <div style={styles.registerAndLoginBar}>
          <div
            onClick={() => handleGoToRegisterScreen(navigate)}
            style={styles.registerButton}
          >
            <p style={styles.registerText}>Register</p>
          </div>
          <div
            onClick={() => handleGoToLoginScreen(navigate)}
            style={styles.loginButton}
          >
            <p className="bolden-gate" style={styles.loginText}>
              Log in
            </p>
          </div>
        </div>
      )}

      <div id={"home"} style={styles.linearGradientMain} />

      {(!isMobile && (
        <div style={styles.primaryTitleBar}>
          <div style={styles.secondaryTitleBar}>
            <h1
              style={{
                ...styles.mainTitlePrimary,
                color: theme === "light" ? "#5a59c9" : "white",
              }}
            >
              JORDAN
            </h1>
            <h1
              className="bolden-gate"
              style={{
                ...styles.mainTitleSecondary,
                color: theme === "light" ? "#5a59c9" : "#6e6ef4",
              }}
            >
              JANCIC
            </h1>
          </div>
          <div style={styles.subtitleOuterContainer}>
            <p
              style={{
                ...styles.subtitleInnerContainer,
                color: theme === "light" ? "#5a59c9" : "white",
              }}
            >
              &nbsp;SOFTWARE&nbsp;SOLUTIONS&nbsp;
            </p>
          </div>
        </div>
      )) || (
        <div style={styles.primaryTitleBarMobile}>
          <div style={styles.secondaryTitleBarMobile}>
            <h1
              style={{
                ...styles.mainTitlePrimaryMobile,
                color: theme === "light" ? "#5a59c9" : "white",
              }}
            >
              JORDAN
            </h1>
            <h1
              className="bolden-gate"
              style={{
                ...styles.mainTitleSecondaryMobile,
                color: theme === "light" ? "#5a59c9" : "#6e6ef4",
              }}
            >
              JANCIC
            </h1>
          </div>
          <div style={styles.subtitleContainerMobile}>
            <p
              style={{
                ...styles.subtitleTextMobile,
                color: theme === "light" ? "#5a59c9" : "white",
              }}
            >
              &nbsp;SOFTWARE&nbsp;SOLUTIONS&nbsp;
            </p>
          </div>
        </div>
      )}

      <NavBar
        theme={theme}
        handleMoveToComponent={(value) => handleMoveToComponent(value)}
        handleToggleTheme={() => handleToggleTheme()}
        handleGoToLoginScreen={() => handleGoToLoginScreen(navigate)}
        handleGoToRegisterScreen={() => handleGoToRegisterScreen(navigate)}
      />

      <div style={{ height: 45 }} />

      <div id={"projects"} style={styles.projectsContainerMain}>
        <GlassContainer
          disabled={isMobile}
          style={styles.glassContainerProjects}
          theme={theme}
        >
          <div style={styles.projectsHeaderContainer}>
            <div
              style={{
                ...styles.projectsHeaderSecondaryContainer,
                marginTop: !isMobile ? 35 : 0,
              }}
            >
              <h2
                className="bolden-gate"
                style={
                  !isMobile ? styles.projectsTitleMobile : styles.projectsTitle
                }
              >
                PROJECTS
              </h2>
              <div style={styles.projectsDescriptionTextContainer}>
                <p
                  style={
                    isMobile
                      ? styles.projectsDescriptionTextMobile
                      : styles.projectsDescriptionText
                  }
                >
                  Explore a selection of my past projects that showcase my
                  skills and creativity as a developer. Each project reflects my
                  commitment to quality, innovation, and user-centric design.
                  From web applications to mobile solutions, I strive to create
                  impactful experiences that address real-world challenges.
                </p>
              </div>
            </div>
          </div>
          <div style={styles.projectsContentContainer}>
            {portfolioProjects.map((project, index) => (
              <ProjectItem
                key={project.id}
                title={project.title}
                description={project.description}
                icon={project.icon}
                technologies={project.technologies}
                sourceCodeAvailable={project.sourceCodeAvailable}
                onClick={() => window.open(formatUrl(project.url), "_blank")}
                url={project.url}
              />
            ))}
          </div>
        </GlassContainer>
      </div>

      <div style={styles.articlesContainerMain}></div>
      <div id={"about"} style={styles.articlesInnerContainer}>
        {newsArticles.map((article, index) => (
          <BasicNewsArticle
            key={index}
            theme={theme}
            articleDate={article.articleDate}
            imageUrl={article.image.url}
            imageCaption={article.imageCaption}
            imageCaptionAuthor={article.imageCaptionAuthor}
            title={article.title}
            subtitle={article.subtitle}
            subtitleAuthor={article.subtitleAuthor}
            articleBody={article.articleBody}
            articleAuthor={article.articleAuthor}
          />
        ))}
      </div>

      <div id={"contact"} style={styles.contactContainerMain}>
        {(!isMobile && (
          <GlassContainer
            disabled={isMobile}
            style={styles.glassContainerContact}
            theme={theme}
          >
            <div style={{ width: "50%" }}>
              {!contactFormSubmitted && (
                <h2
                  style={
                    isMobile ? styles.contactTitle : styles.contactTitleMobile
                  }
                >
                  Please complete the form below:
                </h2>
              )}

              <div
                style={{
                  ...styles.contactForm,
                  height: contactFormLoading === true ? 350 : "auto",
                }}
              >
                {(contactFormLoading && (
                  <div
                    className="sweet-loading"
                    style={styles.contactLoadingOverlayMain}
                  >
                    <BounceLoader
                      color={contactFormIndicatorColor}
                      loading={contactFormLoading}
                      size={100}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                      speedMultiplier={1}
                    />
                    <h3
                      style={{
                        ...styles.contactLoadingText,
                        padding: !isMobile ? 30 : 0,
                      }}
                    >
                      making contact...
                    </h3>
                  </div>
                )) ||
                  (contactFormSubmitted && (
                    <div style={styles.contactFormSubmittedContainerMain}>
                      <FaRegCheckCircle
                        size={55}
                        style={styles.checkCircleStyle}
                      />
                      <h3
                        style={{
                          ...styles.messageReceivedText,
                          padding: !isMobile ? 15 : 0,
                        }}
                      >
                        Message received! I'll be in touch shortly.
                      </h3>
                    </div>
                  )) || (
                    <form
                      onSubmit={handleSubmit}
                      style={styles.outerContainerForm}
                    >
                      <input
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        style={styles.formInputTextName}
                      />
                      <input
                        type="email"
                        name="email"
                        placeholder="Your Email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                        style={styles.formInputEmail}
                      />
                      <textarea
                        name="message"
                        placeholder="Your Message"
                        value={formData.message}
                        onChange={handleInputChange}
                        required
                        rows="4"
                        style={styles.formInputMessage}
                      />
                      <button
                        type="submit"
                        style={{
                          ...styles.formSubmitButton,
                          backgroundColor:
                            theme === "light" ? "#5a59c9" : "#6e6ef4",
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.backgroundColor =
                            theme === "light" ? "#6e6ef4" : "white";
                          e.target.style.color =
                            theme === "light" ? "#6e6ef4" : "black";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor =
                            theme === "light" ? "#6e6ef4" : "#6e6ef4";
                          e.target.style.color =
                            theme === "light" ? "#ffffff" : "white";
                        }}
                      >
                        Send Message
                      </button>
                      {contactFormHasErrors && (
                        <p
                          className="bolden-gate"
                          style={styles.contactErrorText}
                        >
                          Error: Please try again later!
                        </p>
                      )}
                      <div style={{ height: 50 }}></div>
                    </form>
                  )}
              </div>
            </div>
            <div style={styles.contactHeaderContainerMain}>
              <h2 className="bolden-gate" style={styles.contactTitle}>
                CONTACT
              </h2>
              <h3 style={styles.contactSubtitle}>
                Feel free to reach out if you have any questions or need more
                information. I'm here to help. Just fill out the form, and I'll
                get back to you as soon as possible. Looking forward to
                connecting with you!
              </h3>
              <h3 style={styles.contactJordan}>- Jordan</h3>
            </div>
          </GlassContainer>
        )) || (
          <GlassContainer
            disabled={isMobile}
            style={styles.glassContainerContactMobile}
            theme={theme}
          >
            <h2 style={styles.contactTitleMobileSecondary}>CONTACT</h2>
            <div style={styles.contactFormContainer}>
              {(contactFormLoading && (
                <div
                  className="sweet-loading"
                  style={styles.contactFormLoadingMobile}
                >
                  <BounceLoader
                    color={contactFormIndicatorColor}
                    loading={contactFormLoading}
                    size={100}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    speedMultiplier={1}
                  />
                  <h3 style={styles.contactFormLoadingTextMobile}>
                    making contact...
                  </h3>
                </div>
              )) ||
                (contactFormSubmitted && (
                  <div style={styles.contactFormSubmittedMobile}>
                    <FaRegCheckCircle size={55} style={{ color: "#03fc0b" }} />
                    <h3 style={styles.contactFormCircleCheckMobile}>
                      Message received! I'll be in touch shortly.
                    </h3>
                  </div>
                )) || (
                  <form
                    onSubmit={handleSubmit}
                    style={styles.contactFormInnerContainerMobile}
                  >
                    <input
                      type="text"
                      name="name"
                      placeholder="Your Name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                      style={styles.contactFormInputNameMobile}
                    />
                    <input
                      type="email"
                      name="email"
                      placeholder="Your Email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                      style={styles.contactFormInputEmailMobile}
                    />
                    <textarea
                      name="message"
                      placeholder="Your Message"
                      value={formData.message}
                      onChange={handleInputChange}
                      required
                      rows="4"
                      style={styles.contactFormInputMessageMobile}
                    />
                    <button
                      type="submit"
                      style={styles.contactFormSubmitButtonMobile}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor =
                          theme === "light" ? "#6e6ef4" : "white";
                        e.target.style.color =
                          theme === "light" ? "#6e6ef4" : "black";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor =
                          theme === "light" ? "#6e6ef4" : "#6e6ef4";
                        e.target.style.color =
                          theme === "light" ? "#ffffff" : "white";
                      }}
                    >
                      Send Message
                    </button>
                    {contactFormHasErrors && (
                      <p
                        className="bolden-gate"
                        style={styles.contactFormErrorsMobile}
                      >
                        Error: Please try again later!
                      </p>
                    )}
                    <div style={{ height: 50 }}></div>
                  </form>
                )}
            </div>
          </GlassContainer>
        )}
      </div>

      <div style={{ height: 20 }} />
      {(!isMobile && (
        <>
          <div style={{ height: 50 }}></div>
          <Footer
            handleMoveToComponent={(value) => handleMoveToComponent(value)}
          />
        </>
      )) || (
        <>
          <div style={{ height: 50 }}></div>
          <Footer
            handleMoveToComponent={(value) => handleMoveToComponent(value)}
          />
        </>
      )}
    </div>
  );
}

export default Home;
