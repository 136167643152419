import "../App.css";
import React from "react";
import moment from "moment";

function ProductListItemMobile({handleCheckoutProduct, product, subscriptions, }) {
  let styles = {
    mobileProductListItem: {
      cursor: "pointer",
      userSelect: "none",
      textAlign: "center",
      width: "100%",
      border: "1px solid #7484F9",
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#281c56",
      borderRadius: 5,
      flexDirection: "column",
      marginTop: 5,
      marginBottom: "auto",
    },
    mobileProductItemInner: {
      width: "100%",
      flexDirection: "row",
      display: "flex",
    },
    mobileProductImageContainer: {
      cursor: "pointer",
      width: "25%",
      border: "0px solid yellow",
      padding: 10,
    },
    mobileProductImageStyle: {
      maxHeight: "100%",
      objectFit: "contain",
      borderRadius: 5,
      border: "2px solid #3C4684",
    },
    mobileProductInfo: {
      height: "100%",
      width: "75%",
      display: "flex",
      flexDirection: "column",
      marginBottom: "auto",
      maxHeight: 200,
      alignItems: "flex-end",
    },
    mobileProductTitleContainer: {
      width: "100%",
      display: "flex",
      border: "0px solid yellow",
      flexDirection: "row",
      paddingTop: 5,
    },
    mobileProductTitleContainerInner: {
      width: "75%",
      border: "0px solid yellow",
      marginTop: 7,
      display: "flex",
      flexDirection: "row",
    },
    mobileProductName: {
      lineHeight: 1.3,
      paddingBottom: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: "white",
      fontSize: 22,
    },
    mobileProductSpacer: {
      lineHeight: 1,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: "white",
      fontSize: 22,
    },
    mobileProductDescriptionContainer: {
      width: "100%",
      display: "flex",
      border: "0px solid green",
      flexDirection: "row",
    },
    mobileProductDescriptionInnerContainer: {
      width: "100%",
      paddingLeft: 0,
      paddingRight: 20,
      border: "0px solid yellow",
      display: "flex",
      flexDirection: "row",
    },
    mobileProductDescriptionText: {
      textAlign: "left",
      color: "#C6C6F2",
      fontSize: 16,
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
    },
    mobileProductPriceContainer: {
      width: "100%",
      marginTop: "auto",
      marginBottom: 10,
      display: "flex",
      border: "0px solid red",
      flexDirection: "column",
    },
    mobileProductPriceInnerContainer: {
      width: "100%",
      paddingLeft: 0,
      paddingRight: 20,
      border: "0px solid yellow",
      display: "flex",
      flexDirection: "column",
    },
    mobileProductMatchSub: {
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textAlign: "left",
      color: "orange",
      fontSize: 14,
    },
    mobileProductPrice: {
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textAlign: "left",
      color: "#2AD640",
      fontSize: 14,
    },
  };

  return (
    <div
      onClick={() =>
        handleCheckoutProduct(product.stripePriceId, product.stripeProductId)
      }
      style={styles.mobileProductListItem}
    >
      <div style={styles.mobileProductItemInner}>
        <div style={styles.mobileProductImageContainer}>
          <img
            src={product.iconThumbnail.url}
            alt={product.iconThumbnail.alt}
            style={styles.mobileProductImageStyle}
          />
        </div>
        <div style={styles.mobileProductInfo}>
          <div style={styles.mobileProductTitleContainer}>
            <div style={styles.mobileProductTitleContainerInner}>
              <p
                className="bolden-gate"
                style={styles.mobileProductName}
              >{`${product.name}`}</p>
              <p className="bolden-gate" style={styles.mobileProductSpacer}>
                &nbsp;
              </p>
              <div style={{ marginTop: 3 }}></div>
            </div>
          </div>

          <div style={styles.mobileProductDescriptionContainer}>
            <div style={styles.mobileProductDescriptionInnerContainer}>
              <p style={styles.mobileProductDescriptionText}>
                {`${product.description}`}
              </p>
            </div>
          </div>

          <div style={styles.mobileProductPriceContainer}>
            <div style={styles.mobileProductPriceInnerContainer}>
              {subscriptions.some(
                (sub) => sub.stripeProduct.id === product.id
              ) && (
                <p className="bolden-gate" style={styles.mobileProductMatchSub}>
                  {`You are currently subscribed to ${subscriptions.filter((sub) => sub.stripeProduct.id === product.id).length} ${subscriptions.filter((sub) => sub.stripeProduct.id === product.id).length === 1 ? "instance" : "instances"} of this product. Click here to add additional subscriptions.`}
                </p>
              )}

              <p className="bolden-gate" style={styles.mobileProductPrice}>
                {`Price: $${product.subscriptionPrice / 100} CAD ${product.billingFrequency}`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductListItemMobile;
