// JJ-108
import React, { useState, useEffect, useRef } from "react";
import { FaTimes } from "react-icons/fa";
import Hixenis from "../images/HIXENIS_NEW_W_P.png";
import BounceLoader from "react-spinners/BounceLoader";
import {
  resetPassword,
  validatePasswordResetCode,
  changePassword,
} from "../api/dataService";

function PasswordResetWindow({ style, windowHeight, closeWindow }) {
  const [visible, setVisible] = useState(false);
  const [loading, setLoadingState] = useState(false);
  const [loadingStateMessage, setLoadingStateMessage] = useState("");
  const [messageOverlayOpen, setMessageOverlayOpen] = useState(false);
  const [messageOverlayTitleText, setMessageOverlayTitleText] = useState("");
  const [messageOverlayTitleTextColor, setMessageOverlayTitleTextColor] =
    useState("#bbbbed");
  const [messageOverlayText, setMessageOverlayText] = useState("");

  const [emailInputActive, setEmailInputActive] = useState(true);
  const [passwordResetCodeInputActive, setPasswordResetCodeInputActive] =
    useState(false);

  const [emailInputVisible, setEmailInputVisible] = useState(true);
  const [passwordResetCodeInputVisible, setPasswordResetCodeInputVisible] =
    useState(false);

  const [passwordFieldsDisabled, setPasswordFieldsDisabled] = useState(false);

  const [passwordResetCode, setPasswordResetCode] = useState();

  const [fadeIn, setFadeIn] = useState(false);
  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");
  const [passwordConfirmed, setPasswordConfirmed] = useState("");

  const [messageOverlayWidth, setMessageOverlayWidth] = useState(0); //max 255

  const [phaseOneEnabled, setPhaseOneEnabled] = useState(true);
  const [phaseTwoEnabled, setPhaseTwoEnabled] = useState(false);
  const [phaseThreeEnabled, setPhaseThreeEnabled] = useState(false);

  const handleResetPasswordClick = (e) => {
    setLoadingState(true);
    setLoadingStateMessage("Requesting...");

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (!email || !emailRegex.test(email)) {
      setMessageOverlayOpen(true);

      setMessageOverlayTitleText("INVALID EMAIL");
      setMessageOverlayText("Please enter a valid email address.");
      setLoadingState(false);
      return;
    }

    resetPassword({ email: email })
      .then((response) => {
        if (response.status === "OK") {
          setPhaseOneEnabled(false);
          setPhaseTwoEnabled(true);
          console.log(response);
          setEmailInputActive(false);
          setPasswordResetCodeInputActive(true);
          setLoadingState(false);
          setMessageOverlayTitleText("CODE SENT");
          setMessageOverlayTitleTextColor("#03fc0b");
          setMessageOverlayText("We sent a password reset code to your email.");
          setMessageOverlayOpen(true);
        } else {
          console.log(response);
          setLoadingState(false);
          setMessageOverlayOpen(true);
          setMessageOverlayTitleText("ERROR");
          setMessageOverlayTitleTextColor("red");
          setMessageOverlayText(`${response.detail}`);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingState(false);
        setMessageOverlayOpen(true);
        setMessageOverlayTitleText("ERROR");
        setMessageOverlayTitleTextColor("red");
        setMessageOverlayText(`${error.detail}`);
      });
  };

  const handleSubmitCodeClick = (e) => {
    setLoadingState(true);
    setLoadingStateMessage("Validating code...");

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (!email || !emailRegex.test(email)) {
      setMessageOverlayOpen(true);

      setMessageOverlayTitleText("Invalid Email");
      setMessageOverlayText("Please enter a valid email address.");
      setLoadingState(false);
      return;
    }

    validatePasswordResetCode({
      email: email,
      passwordResetCode: passwordResetCode,
    })
      .then((response) => {
        if (response.status === "OK") {
          setPhaseTwoEnabled(false);
          setPhaseThreeEnabled(true);
          setPasswordResetCodeInputActive(false);
          setLoadingState(false);
          setMessageOverlayTitleText("CODE VALID");
          setMessageOverlayTitleTextColor("#03fc0b");
          setMessageOverlayText(
            "Your code has been validated. Please enter your new password."
          );
          setEmailInputActive(false);
          setEmailInputVisible(false);

          setMessageOverlayOpen(true);
        } else {
          console.log(response);
          setLoadingState(false);
          setMessageOverlayOpen(true);
          setMessageOverlayTitleText("ERROR");
          setMessageOverlayTitleTextColor("red");
          setMessageOverlayText(`${response.detail}`);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingState(false);
        setMessageOverlayOpen(true);
        setMessageOverlayTitleText("ERROR");
        setMessageOverlayTitleTextColor("red");
        setMessageOverlayText(`${error.detail}`);
      });
  };

  const handleSubmitNewPasswordClick = () => {
    setLoadingState(true);
    setLoadingStateMessage("Changing password...");

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (!email || !emailRegex.test(email)) {
      setMessageOverlayOpen(true);

      setMessageOverlayTitleText("Invalid Email");
      setMessageOverlayText("Please enter a valid email address.");
      setLoadingState(false);
      return;
    }

    if (!password || password.length < 8) {
      setMessageOverlayOpen(true);
      setMessageOverlayTitleText("Invalid Password");
      setMessageOverlayText("Password must be at least 8 characters long.");
      setLoadingState(false);
      return;
    }

    if (password !== passwordConfirmed) {
      setMessageOverlayOpen(true);
      setMessageOverlayTitleText("Passwords Do Not Match");
      setMessageOverlayText("Please ensure both password fields match.");
      setLoadingState(false);
      return;
    }

    changePassword({
      email: email,
      passwordResetCode: passwordResetCode,
      password: password,
    })
      .then((response) => {
        if (response.status === "OK") {
          setPhaseThreeEnabled(false);
          setEmailInputActive(false);
          setPasswordResetCodeInputActive(false);
          setLoadingState(false);
          setMessageOverlayTitleText("PASSWORD CHANGED");
          setMessageOverlayTitleTextColor("#03fc0b");
          setPasswordFieldsDisabled(true);
          setMessageOverlayText(
            "Your password was changed successfully.\nPlease log in with your new credentials."
          );
          setEmailInputVisible(false);
          setMessageOverlayOpen(true);
        } else {
          setLoadingState(false);
          setMessageOverlayOpen(true);
          setMessageOverlayTitleText("ERROR");
          setMessageOverlayTitleTextColor("red");
          setMessageOverlayText(`${response.detail}`);
        }
      })
      .catch((error) => {
        setLoadingState(false);
        setMessageOverlayOpen(true);
        setMessageOverlayTitleText("ERROR");
        setMessageOverlayTitleTextColor("red");
        setMessageOverlayText(`${error.detail}`);
      });
  };

  let styles = {
    mainContainer: {
      ...style,
      position: "absolute",
    },
    loadingContainerMain: {
      position: "absolute",
      zIndex: 12000,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "opacity 1s ease-in-out",
    },
    loadingInnerContainer: {
      padding: 30,
      backgroundColor: "rgba(44, 41, 63, 1)",
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      boxShadow: "0 0px 10px rgba(120, 0, 255, 1)",
      border: "1px solid #6E6EF4",
    },
    messageOverlayContainerMain: {
      position: "absolute",
      zIndex: 12000,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "opacity 1s ease-in-out",
    },
    messageOverlayInnerContainer: {
      width: 350,
      backgroundColor: "#191632",
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      boxShadow: "0 0px 15px rgba(0, 0, 0, .8)",
      border: "1px solid #6E6EF4",
      overflow: "hidden",
    },
    messageOverlayTitleContainer: {
      borderBottom: "0px solid #6E6EF4",
      width: "90%",
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 5,
    },
    messageOverlayTitle: {
      marginTop: 5,
      fontSize: 18,
      color: messageOverlayTitleTextColor,
    },
    messageOverlayBodyContainer: {
      border: "0px solid red",
      width: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      padding: 16,
      paddingTop: 0,
    },
    messageOverlayBody: { marginTop: 5, textAlign: "center" },
    messageOverlayOKButtonContainer: {
      cursor: "pointer",
      borderTop: "1px solid #6E6EF4",
      width: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      padding: 16,
    },
    messageOverlayOKButtonText: {
      fontSize: 22,
      marginTop: 5,
      textAlign: "center",
    },
    passwordResetMainContainer: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    passwordResetInnerContainer: {
      border: "1.5px solid #6e6ef4",
      width: "100%",
      maxWidth: "375px",
      paddingBottom: 30,
      borderRadius: 10,
      transition: "width 0.3s ease",
      boxShadow: "0 0px 15px rgba(120, 0, 255, .5)",
      position: "relative",
    },
    closeWindowButtonContainer: { width: "100%", color: "white" },
    closeWindow: {
      marginLeft: 15,
      marginTop: 15,
      color: "#a6a6fc",
      cursor: "pointer",
    },
    logoContainer: {
      height: "15%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    logo: {
      maxWidth: "80%",
      maxHeight: "100%",
      objectFit: "contain",
    },
    headerContainer: {
      height: "15%",
      color: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 15,
      textAlign: "center",
      flexDirection: "column",
    },
    headerInnerContainer: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "#a6a6fc",
      paddingBottom: 0,
      marginBottom: 0,
      lineHeight: 1,
    },
    phaseContainer: {
      height: "10%",
      color: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    phaseOneMainContainer: {
      width: "100%",
      border: "0px solid red",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
    phaseOneEmailInput: {
      color: "black",
      width: "80%",
      height: 50,
      padding: 15,
      borderRadius: 5,
    },
    phaseOneEmailInputDisabled: {
      color: "black",
      width: "80%",
      height: 50,
      padding: 15,
      borderRadius: 5,
      backgroundColor: "grey",
    },
    phaseTwoCodeInput: {
      marginTop: 15,
      width: "80%",
      height: 50,
      padding: 15,
      borderRadius: 5,
      textAlign: "center",
      color: "#200844",
    },
    phaseThreeMainContainer: { width: "100%" },
    phaseThreeInnerContainer: {
      height: "10%",
      color: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: 10,
    },
    phaseThreePasswordInput: {
      backgroundColor: passwordFieldsDisabled ? "grey" : "white",
      color: "black",
      width: "80%",
      height: 50,
      padding: 15,
      borderRadius: 5,
    },
    phaseThreePasswordConfirmContainer: {
      height: "10%",
      color: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    phaseThreePasswordConfirm: {
      backgroundColor: passwordFieldsDisabled ? "grey" : "white",
      color: "black",
      width: "80%",
      height: 50,
      padding: 15,
      borderRadius: 5,
    },
    getCodeContainer: {
      height: "8%",
      color: "white",
      display: "flex",
      marginTop: 20,
      justifyContent: "center",
    },
    phaseOneGetCodeButton: {
      color: "white",
      backgroundColor: "#6e6ef4",
      width: "80%",
      height: 50,
      padding: 15,
      borderRadius: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    phaseTwoValidateCodeButton: {
      color: "white",
      backgroundColor: "#6e6ef4",
      width: "80%",
      height: 50,
      padding: 15,
      borderRadius: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    phaseThreeChangePasswordButton: {
      color: "white",
      backgroundColor: "#6e6ef4",
      width: "80%",
      height: 50,
      padding: 15,
      borderRadius: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    backToLoginButton: {
      color: "white",
      backgroundColor: "green",
      width: "80%",
      height: 50,
      padding: 15,
      borderRadius: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    legal: {
      height: "15%",
      color: "#a6a6fc",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      padding: 20,
      fontSize: "0.65rem",
      lineHeight: "1.5",
    },
  };

  return (
    <div style={styles.mainContainer}>
      {loading && (
        <div style={styles.loadingContainerMain}>
          <div style={styles.loadingInnerContainer}>
            <BounceLoader
              color={"#717DFA"}
              size={50}
              aria-label="Loading Spinner"
              data-testid="loader"
              speedMultiplier={1}
            />
            <p style={{ marginTop: 5 }}>{loadingStateMessage}</p>
          </div>
        </div>
      )}

      {messageOverlayOpen && (
        <div style={styles.messageOverlayContainerMain}>
          <div style={styles.messageOverlayInnerContainer}>
            <div style={styles.messageOverlayTitleContainer}>
              <p className="bolden-gate" style={styles.messageOverlayTitle}>
                {messageOverlayTitleText}
              </p>
            </div>
            <div style={styles.messageOverlayBodyContainer}>
              <p style={styles.messageOverlayBody}>{messageOverlayText}</p>
            </div>
            <div
              onClick={() => setMessageOverlayOpen(false)}
              style={styles.messageOverlayOKButtonContainer}
            >
              <p style={styles.messageOverlayOKButtonText}>{"OK"}</p>
            </div>
          </div>
        </div>
      )}

      <div style={styles.passwordResetMainContainer}>
        <div
          className="frosted-glass-container-blur-plus-plus-plus"
          style={styles.passwordResetInnerContainer}
        >
          <div style={styles.closeWindowButtonContainer}>
            <FaTimes
              onClick={closeWindow}
              size={25}
              style={styles.closeWindow}
            />
          </div>

          <div style={styles.logoContainer}>
            <img src={Hixenis} alt="Logo" style={styles.logo} />
          </div>

          <div style={styles.headerContainer}>
            <div style={styles.headerInnerContainer}>Reset Your Password</div>
            <div style={{ fontSize: "1rem" }}>
              Enter the email associated with your HIXENIS account below.
            </div>
          </div>

          <div style={styles.phaseContainer}>
            {phaseOneEnabled && (
              <div style={styles.phaseOneMainContainer}>
                {(emailInputActive && (
                  <input
                    style={styles.phaseOneEmailInput}
                    type={"email"}
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                )) || (
                  <input
                    disabled
                    style={styles.phaseOneEmailInputDisabled}
                    type={"email"}
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                )}
              </div>
            )}

            {passwordResetCodeInputActive && phaseTwoEnabled && (
              <input
                className="bolden-gate"
                style={styles.phaseTwoCodeInput}
                type={"text"}
                placeholder="Enter your code"
                value={passwordResetCode}
                onChange={(e) => {
                  const newValue = e.target.value
                    .replace(/[^0-9]/g, "")
                    .slice(0, 6);
                  setPasswordResetCode(newValue);
                }}
                maxLength={6}
              />
            )}

            {phaseThreeEnabled && (
              <div style={styles.phaseThreeMainContainer}>
                <div style={styles.phaseThreeInnerContainer}>
                  <input
                    disabled={passwordFieldsDisabled ? true : false}
                    style={styles.phaseThreePasswordInput}
                    type={"password"}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div style={styles.phaseThreePasswordConfirmContainer}>
                  <input
                    disabled={passwordFieldsDisabled ? true : false}
                    style={styles.phaseThreePasswordConfirm}
                    type={"password"}
                    placeholder="Confirm Password"
                    value={passwordConfirmed}
                    onChange={(e) => setPasswordConfirmed(e.target.value)}
                  />
                </div>
              </div>
            )}
          </div>

          <div style={styles.getCodeContainer}>
            {phaseOneEnabled && (
              <button
                className="bolden-gate"
                onClick={() => handleResetPasswordClick()}
                style={styles.phaseOneGetCodeButton}
              >
                Get Code
              </button>
            )}
            {phaseTwoEnabled && (
              <button
                className="bolden-gate"
                onClick={() => handleSubmitCodeClick()}
                style={styles.phaseTwoValidateCodeButton}
              >
                Validate Code
              </button>
            )}
            {phaseThreeEnabled && (
              <button
                className="bolden-gate"
                onClick={() => handleSubmitNewPasswordClick()}
                style={styles.phaseThreeChangePasswordButton}
              >
                Change Password
              </button>
            )}
            {!phaseOneEnabled && !phaseTwoEnabled && !phaseThreeEnabled && (
              <button
                className="bolden-gate"
                onClick={() => closeWindow()}
                style={styles.backToLoginButton}
              >
                Back to Login
              </button>
            )}
          </div>

          <div style={styles.legal}>
            By initiating a password reset, you agree to our Terms of Service
            and Privacy Policy. Your data will be handled securely during the
            reset process. Please ensure that you follow the instructions
            carefully to protect your account.
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordResetWindow;
