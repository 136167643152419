// JJ-108
import "../App.css"; // Ensure your CSS is linked
import React, { useState, useEffect, Fragment } from "react";
import moment from "moment/moment";
import escapeHTML from "escape-html";
import { Text } from "slate";
import { dividerClasses } from "@mui/material";

function BasicNewsArticle({
  theme,
  articleDate,
  imageUrl,
  imageCaption,
  imageCaptionAuthor,
  title,
  subtitle,
  subtitleAuthor,
  articleBody,
  articleAuthor,
}) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Adjust the threshold as needed

  const handleResize = () => {
    const isCurrentlyMobile = window.innerWidth < 768;
    setIsMobile(isCurrentlyMobile);
  };

  const escapeHTML = (text) => text.replace(/</g, "&lt;").replace(/>/g, "&gt;");

  const serialize = (children) =>
    children.map((node, i) => {
      // Handle Text Nodes
      if (Text.isText(node)) {
        let text = (
          <span dangerouslySetInnerHTML={{ __html: escapeHTML(node.text) }} />
        );

        if (node.bold) {
          text = <strong key={i}>{text}</strong>;
        }

        return <React.Fragment key={i}>{text}</React.Fragment>;
      }

      // Handle Blocks & Other Elements
      if (!node) {
        return null;
      }

      switch (node.type) {
        case "h3":
          return <h3 key={i}>{serialize(node.children)}</h3>;

        case "ul":
          return (
            <ul key={i}>
              {node.children.map((child, childIndex) => (
                <li key={childIndex}>{serialize(child.children)}</li>
              ))}
            </ul>
          );
        case "li":
          return <li key={i}>{serialize(node.children)}</li>;

        default:
          return <p key={i}>{serialize(node.children)}</p>;
      }
    });
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let styles = {
    mainContainer: {
      border: "1px solid #262e59",
      display: "flex",
      flexDirection: "row",
      width: "75%",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginBottom: 15,
      padding: 15,
    },
    leftPanel: { padding: 20, width: "40%", border: "0px solid white" },
    imageStyle: {
      width: "100%",
      borderRadius: 20,
      boxShadow: "0 4px 20px rgba(0, 0, 0, 0.5)",
    },
    rightPanel: {
      padding: 20,
      width: "60%",
      height: "auto",
    },
    articleTitle: {
      textAlign: "right",
      margin: 0,
      color: theme === "light" ? "#5a59c9" : "#7c7cf2",
      userSelect: "none",
      fontSize: 45,
      lineHeight: 1.1,
      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
    },
    articleSubtitle: {
      marginTop: 5,
      textAlign: "right",
      margin: 0,
      color: theme === "light" ? "#5a59c9" : "white",
      userSelect: "none",
      fontSize: 20,
      lineHeight: 1.3,
      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
    },
    subtitleText: {
      textAlign: "right",
      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
    },
    divider: {
      height: 10,
      marginBottom: 10,
      borderBottomWidth: 0.5,
      borderBottom: "1px solid #4f4fad",
    },
    articleDate: { textAlign: "right", marginBottom: 10 },
    articleBodyText: {
      whiteSpace: "pre-wrap",
      textAlign: "left",
      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
    },
    mainContainerMobile: {
      border: isMobile ? null : "1px solid #262e59",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginBottom: 15,
      padding: 20,
      borderRadius: 0,
      color: "white",
    },
    articleTitleMobile: {
      textAlign: "right",
      margin: 0,
      color: theme === "light" ? "#5a59c9" : "#7c7cf2",
      userSelect: "none",
      fontSize: 45,
      lineHeight: 1.1,
      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
      marginBottom: 10,
    },
    articleSubtitleMobile: {
      marginTop: 5,
      textAlign: "right",
      margin: 0,
      color: theme === "light" ? "#5a59c9" : "white",
      userSelect: "none",
      fontSize: 20,
      lineHeight: 1.3,
      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
    },
    subtitleAuthorTextMobile: {
      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
      textAlign: "right",
    },
    spacer: {
      height: 10,
      marginBottom: 10,
      borderBottomWidth: 0.5,
      borderBottom: "1px solid #4f4fad",
    },
    imageStyleMobile: {
      width: "100%",
      borderRadius: 20,
      boxShadow: "0 4px 20px rgba(0, 0, 0, 0.5)", // Add drop shadow
    },
    articleBodyTextMobile: {
      whiteSpace: "pre-wrap",
      textAlign: "left",
      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
    },
  };

  return (
    (!isMobile === true && (
      <div
        className="frosted-glass-container-blur-plus-plus"
        style={styles.mainContainer}
      >
        <div style={styles.leftPanel}>
          <img
            src={`${imageUrl}`}
            alt="Description"
            style={styles.imageStyle}
          />

          <p style={{ textAlign: "right", lineHeight: 2 }}>{imageCaption}</p>
          <p style={{ textAlign: "right", lineHeight: 1 }}>
            Photo by {imageCaptionAuthor}
          </p>
        </div>
        <div style={styles.rightPanel}>
          <h2 className="bolden-gate" style={styles.articleTitle}>
            {title}
          </h2>
          <h3 style={styles.articleSubtitle}>{subtitle}</h3>
          <h3 className="bolden-gate" style={styles.subtitleText}>
            {" "}
            {subtitleAuthor}
          </h3>

          <div style={styles.divider} />

          <p style={styles.articleDate}>
            {moment(articleDate).format("MMMM Do, YYYY")}
          </p>
          {articleBody.map((section, index) => (
            <p key={index} style={styles.articleBodyText}>
              {serialize(section.children)}{" "}
            </p>
          ))}
          <br />
          <p style={{ textAlign: "right" }}>{`- ${articleAuthor}`}</p>
        </div>
      </div>
    )) || (
      <div style={styles.mainContainerMobile}>
        <div style={{ width: "100%" }}>
          <h2 className="bolden-gate" style={styles.articleTitleMobile}>
            {title}
          </h2>
        </div>

        <div style={{ width: "100%" }}>
          <h3 style={styles.articleSubtitleMobile}>{subtitle}</h3>
        </div>

        <div style={{ width: "100%" }}>
          <h3 className="bolden-gate" style={styles.subtitleAuthorTextMobile}>
            {" "}
            {subtitleAuthor}
          </h3>
          <div style={styles.divider} />
        </div>

        <div style={styles.spacer} />
        <img
          src={`${imageUrl}`}
          alt="Description"
          style={styles.imageStyleMobile}
        />
        <div style={{ width: "100%" }}>
          <p style={{ textAlign: "right", lineHeight: 2 }}>{imageCaption}</p>
          <p style={{ textAlign: "right", lineHeight: 1 }}>
            Photo by {imageCaptionAuthor}
          </p>
        </div>

        <p style={{ textAlign: "right", marginTop: 10 }}>
          {moment(articleDate).format("MMMM Do, YYYY")}
        </p>
        <br />
        {articleBody.map((section, index) => (
          <p key={index} style={styles.articleBodyTextMobile}>
            {serialize(section.children)}{" "}
          </p>
        ))}
        <br />
        <p style={{ textAlign: "right" }}>{`- ${articleAuthor}`}</p>
      </div>
    )
  );
}

export default BasicNewsArticle;
