import axios from "axios";

var domain = `api.${window.location.hostname.replace(/^www\./, '')}`;

if(domain === 'api.localhost') {
  domain = 'api.jordanjancic.com';
}

//Public
export const register = (formData) => {
  return axios
    .post(`https://${domain}/api/register`, formData, {
      withCredentials: true,  // Ensure cookies are sent with the request
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error submitting form:", error);
      throw error;
    });
};

//Public
export const loginWithEmailAndPassword = (formData) => {
  return axios
    .post(`https://${domain}/api/loginWithEmailAndPassword`, formData, {
      withCredentials: true,  // Ensure cookies are sent with the request
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error submitting form:", error);
      throw error;
    });
};

//Public
export const fetchNewsArticles = () => {
  return axios
    .get(`https://${domain}/api/getNewsArticles`) // Hardcoding to test
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching news articles:", error);
      throw error;
    });
};

//Public
export const fetchPortfolioProjects = () => {
  return axios
    .get(`https://${domain}/api/getPortfolioProjects`) // Hardcoding to test
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching portfilio projects:", error);
      throw error;
    });
};

//Public
export const getProducts = () => {
  return axios
    .get(`https://${domain}/api/getProducts`, {
      withCredentials: true  // Important for cookies
    }) // Using POST to send form data
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error getting products:", error);
      throw error;
    });
};

//Public
export const submitContactForm = (formData) => {
  return axios
    .post(`https://${domain}/api/submitContactForm`, formData, {
      withCredentials: true  // Important for cookies
    }) // Using POST to send form data
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error submitting form:", error);
      throw error;
    });
};



//Public
export const resetPassword = (formData) => {
  return axios
    .post(`https://${domain}/api/resetPassword`, formData, {
      withCredentials: true  // Important for cookies
    }) // Using POST to send form data
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error resetting password:", error);
      throw error;
    });
};


//Public
export const validatePasswordResetCode = (formData) => {
  return axios
    .post(`https://${domain}/api/validatePasswordResetCode`, formData, {
      withCredentials: true  // Important for cookies
    }) // Using POST to send form data
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error resetting password:", error);
      throw error;
    });
};

//Public
export const changePassword = (formData) => {
  return axios
    .post(`https://${domain}/api/changePassword`, formData, {
      withCredentials: true  // Important for cookies
    }) // Using POST to send form data
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error resetting password:", error);
      throw error;
    });
};

//Protected
export const changeUserPassword = (formData) => {
  return axios
  .post(`https://${domain}/api/changeUserPassword`, formData, {
    withCredentials: true  // Important for cookies
  }) // Using POST to send form data
  .then((response) => response.data)
  .catch((error) => {
    console.error("Error resetting password:", error);
    throw error;
  });
};

//Protected
export const cancelSubscription = (formData) => {
  return axios
  .post(`https://${domain}/api/cancelSubscription`, formData, {
    withCredentials: true  // Important for cookies
  }) // Using POST to send form data
  .then((response) => response.data)
  .catch((error) => {
    console.error("Error cancelling subscription:", error);
    throw error;
  });
};

//Protected
export const getEmailVerificationStatus = (formData) => {
  if (!formData?.userId) {
    return Promise.reject(new Error("Missing userId in formData"));
  }
  return axios
    .post(`https://${domain}/api/getEmailVerificationStatus`, formData, {
      withCredentials: true // Important for cookies
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error retrieving email verification status:", error);
      throw error;
    });
};

//Protected
export const resendVerificationEmail = (formData) => {
  if (!formData?.userId) {
    return Promise.reject(new Error("Missing userId in formData"));
  }
  return axios
    .post(`https://${domain}/api/resendVerificationEmail`, formData, {
      withCredentials: true // Important for cookies
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error retrieving email verification status:", error);
      throw error;
    });
};

//Protected
export const getSubscriptions = (formData) => {
  if (!formData?.userId) {
    return Promise.reject(new Error("Missing userId in formData"));
  }
  return axios
    .post(`https://${domain}/api/getSubscriptions`, formData, {
      withCredentials: true // Important for cookies
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error getting subscriptions:", error);
      throw error;
    });
};
//Protected
export const updateUserAccount = (formData) => {
  
  if (!formData?.userId) {
    return Promise.reject(new Error("Missing userId in formData"));
  }
  return axios
    .post(`https://${domain}/api/updateUserAccount`, formData, {
      withCredentials: true // Important for cookies
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error updating user account:", error);
      throw error;
    });
};
