// JJ-108
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

function AdminDashboard() {
  const location = useLocation();
  const props = location.state || {}; // Safely extract data

  return (
    <div className="bolden-gate" style={styles.container}>
      {`Welcome to the Admin dashboard!`}
    </div>
  );
}
let styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 56,
    backgroundColor: "red",
    width: "100%",
    minHeight: "100vh",
    color: "white",
  },
};
export default AdminDashboard;
