import "../App.css";
import React from "react";
import moment from "moment";

function ProductListItem({setSelectedProduct, product, selectedProduct, subscriptions, }) {
  let styles = {
    productListItemMain: {
      cursor: "pointer",
      userSelect: "none",
      textAlign: "center",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#281e51",
      borderRadius: 5,
      flexDirection: "column",
      marginTop: 5,
      marginBottom: "auto",
    },
    productListItemInner: {
      width: "100%",
      flexDirection: "row",
      display: "flex",
      border: "0px solid yellow",
    },
    productThumbnailContainer: {
      width: "25%",
      border: "0px solid yellow",
      padding: 10,
    },
    productImageThumbnail: {
      maxHeight: "100%",
      objectFit: "contain",
      borderRadius: 5,
      border: "2px solid #3C4684",
    },
    productInfo: {
      height: "100%",
      width: "75%",
      display: "flex",
      flexDirection: "column",
      marginBottom: "auto",
      minHeight: 180,
      maxHeight: 200,
      alignItems: "flex-end",
    },
    productInfoTitle: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      paddingTop: 5,
    },
    productInfoInnerTitle: {
      width: "80%",
      marginTop: 7,
      display: "flex",
      flexDirection: "row",
    },
    productInfoName: {
      lineHeight: 1.3,
      paddingBottom: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: "white",
      fontSize: 22,
    },
    productInfoSpacer: {
      lineHeight: 1,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: "white",
      fontSize: 22,
    },
    productInfoDescription: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
    },
    productInfoInnerDescription: {
      width: "100%",
      paddingLeft: 0,
      paddingRight: 20,
      border: "0px solid yellow",
      display: "flex",
      flexDirection: "row",
    },
    productInfoDescriptionText: {
      textAlign: "left",
      color: "#C6C6F2",
      fontSize: 16,
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
    },
    productInfoPrice: {
      width: "100%",
      marginTop: "auto",
      marginBottom: 10,
      display: "flex",
      border: "0px solid red",
      flexDirection: "column",
    },
    productInfoPriceInner: {
      width: "100%",
      paddingLeft: 0,
      paddingRight: 20,
      border: "0px solid yellow",
      display: "flex",
      flexDirection: "column",
    },
    productMatchSub: {
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textAlign: "left",
      color: "orange",
      fontSize: 14,
    },
    productPlanPrice: {
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textAlign: "left",
      color: "#2AD640",
      fontSize: 14,
    },
  };

  return (
    <div
      onClick={() => setSelectedProduct(product)}
      style={{
        ...styles.productListItemMain,
        border:
          selectedProduct.id === product.id
            ? "1px solid #bcc5ff"
            : "1px solid #7484F9",
        boxShadow:
          selectedProduct.id === product.id
            ? "0 0px 25px rgba(120, 0, 255, .4)"
            : "0 0px 15px rgba(0, 0, 0, .5)",
      }}
    >
      <div style={styles.productListItemInner}>
        <div style={styles.productThumbnailContainer}>
          <img
            src={product.iconThumbnail.url}
            alt={product.iconThumbnail.alt}
            style={styles.productImageThumbnail}
          />
        </div>
        <div style={styles.productInfo}>
          <div style={styles.productInfoTitle}>
            <div style={styles.productInfoInnerTitle}>
              <p
                className="bolden-gate"
                style={styles.productInfoName}
              >{`${product.name}`}</p>
              <p className="bolden-gate" style={styles.productInfoSpacer}>
                &nbsp;
              </p>
              <div style={{ marginTop: 3 }}></div>
            </div>
          </div>

          <div style={styles.productInfoDescription}>
            <div style={styles.productInfoInnerDescription}>
              <p style={styles.productInfoDescriptionText}>
                {`${product.description}`}
              </p>
            </div>
          </div>

          <div style={styles.productInfoPrice}>
            <div style={styles.productInfoPriceInner}>
              {subscriptions.some(
                (sub) => sub.stripeProduct.id === product.id
              ) && (
                <p className="bolden-gate" style={styles.productMatchSub}>
                  {`You are currently subscribed to ${subscriptions.filter((sub) => sub.stripeProduct.id === product.id).length} ${subscriptions.filter((sub) => sub.stripeProduct.id === product.id).length === 1 ? "instance" : "instances"} of this product. Click here to add additional subscriptions.`}
                </p>
              )}

              <p className="bolden-gate" style={styles.productPlanPrice}>
                {`Price: $${product.subscriptionPrice / 100} CAD ${product.billingFrequency}`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductListItem;
