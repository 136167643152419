import "./App.css";
import { useCallback, useState, useEffect } from "react";
import {
  Route,
  Routes,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import "./fonts/fonts.css";
import NavBar from "./components/NavBar";
import BasicNewsArticle from "./components/BasicNewsArticle";
import GlassContainer from "./components/GlassContainer";
import ProjectItem from "./components/ProjectItem";
import Footer from "./components/Footer";
import LoginWindow from "./components/LoginWindow";
import { FaRegCheckCircle } from "react-icons/fa";
import BounceLoader from "react-spinners/BounceLoader";
import Home from "./Screens/Home";
import AdminDashboard from "./Screens/AdminDashboard";
import ClientDashboard from "./Screens/ClientDashboard";
import LoginScreen from "./Screens/LoginScreen";
import RegisterScreen from "./Screens/RegisterScreen";
import SupportScreen from "./Screens/SupportScreen";
import PasswordResetScreen from "./Screens/PasswordResetScreen";
import AccountEditScreen from "./Screens/AccountEditScreen";
import Hixenis from "./images/HIXENIS_NEW_W_P.png";
import { useUser } from "./stores/UserContext";
import { FaArrowLeft } from "react-icons/fa";
//
// import {
//   getSubscriptions,
// } from "./api/dataService";

function App() {
  const navigate = useNavigate();
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST
  );

  const CheckoutForm = () => {
    const location = useLocation(); // Access the state passed with navigate
    const { userId, stripePriceId, stripeProductId } = location.state || {}; // Get stripeProductId from state
    var domain = `${window.location.hostname.replace(/^www\./, "")}`;

    if (domain === "localhost") {
      domain = "localhost:3002";
    }

    const fetchClientSecret = useCallback(() => {
      // Create a Checkout Session
      return fetch(`https://api.jordanjancic.com/api/create-checkout-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Ensure the server knows you're sending JSON
        },
        body: JSON.stringify({
          userId: userId,
          domain: domain,
          stripePriceId: stripePriceId, // Send stripeProductId as a key-value pair
          stripeProductId: stripeProductId,
        }),
      })
        .then((res) => res.json())
        .then((data) => data.clientSecret);
    }, [domain, stripePriceId]);

    const options = {
      fetchClientSecret,
    };

    const handleGoBack = () => {
      navigate("ClientDashboard");
    };

    useEffect(() => {
      const handlePopState = (event) => {
        event.preventDefault(); // Prevents default back behavior if needed
        handleGoBack();
      };

      // Add event listener for browser back button
      window.onpopstate = handlePopState;

      // Cleanup the event listener on component unmount
      return () => {
        window.onpopstate = null;
      };
    }, []);

    let styles = {
      mainContainer: { width: "100%" },
      logoContainer: {
        width: "100%",
        color: "white",
        padding: 30,
        flexDirection: "column",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "0px solid yellow",
        backgroundColor: "#090714",
      },
      logo: {
        maxWidth: 280,
        maxHeight: "100%",
        objectFit: "contain",
      },
      customCheckoutContainer: {
        width: "100%",
        color: "white",
        padding: 10,
        flexDirection: "row",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        border: "0px solid yellow",
        backgroundColor: "#090714",
      },
      spacer: { marginLeft: 25, border: "0px solid yellow" },
      goBackButtonContainer: {
        cursor: "pointer",
        border: "0px solid red",
        flexDirection: "row",
        display: "flex",
      },
      goBackButtonText: { marginLeft: 10, color: "#6E6EF4" },
      checkoutContainer: {
        width: "100%",
        minHeight: "100vh",
        backgroundColor: "#090714",
      },

    };

    return (
      <div style={styles.mainContainer}>
        <div
          style={styles.logoContainer}
        >
          <img
            src={Hixenis}
            alt="Logo"
            style={styles.logo}
          />
          <p style={{ color: "#6E6EF4" }}>Secure Checkout</p>
        </div>
        <div
          style={styles.customCheckoutContainer}
        >
          <div style={styles.spacer} />
          <div
            onClick={() => handleGoBack()}
            style={styles.goBackButtonContainer}
          >
            <FaArrowLeft color={"#6E6EF4"} size={24} />
            <p style={styles.goBackButtonText}>
              {"Back to Dashboard"}
            </p>
          </div>
        </div>
        <div
          id="checkout"
          style={styles.checkoutContainer}
        >
          <div style={{ color: "white" }}>
            <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          </div>
        </div>
      </div>
    );
  };

  const Return = (location) => {
    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState("");

    useEffect(() => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const sessionId = urlParams.get("session_id");

      fetch(
        `https://api.jordanjancic.com/api/session-status?session_id=${sessionId}`
      )
        .then((res) => res.json())
        .then((data) => {
          setStatus(data.status);
          setCustomerEmail(data.customer_email);
        });
    }, []);

    if (status === "open") {
      return <Navigate to="/checkout" />;
    }

    if (status === "complete") {
      navigate("/ClientDashboard", { replace: true });
    }

    return null;
  };

  let stylesSecondary = {
    routerContainer: {
      width: "100%",
      minHeight: "100vh",
      display: "flex",
      border: "0px solid red",
    },
  };

  return (
    <div
      style={stylesSecondary.routerContainer}
    >
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/LoginScreen" exact element={<LoginScreen />} />
        <Route path="/RegisterScreen" exact element={<RegisterScreen />} />
        <Route
          path="/PasswordResetScreen"
          exact
          element={<PasswordResetScreen />}
        />
        <Route path="/AdminDashboard" exact element={<AdminDashboard />} />
        <Route path="/ClientDashboard" exact element={<ClientDashboard />} />
        <Route
          path="/AccountEditScreen"
          exact
          element={<AccountEditScreen />}
        />
        <Route path="/SupportScreen" exact element={<SupportScreen />} />
        <Route path="/checkout" element={<CheckoutForm />} />
        <Route path="/return" element={<Return location={useLocation()} />} />
      </Routes>
    </div>
  );
}

export default App;
