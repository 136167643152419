import "../App.css";
import React from "react";
import moment from "moment";

function SubscriptionListItemMobile({ setSelectedSubscription, subscription, selectedSubscription, isMobile }) {
  let styles = {
    mobileSubListItem: {
      cursor: "pointer",
      userSelect: "none",
      textAlign: "center",
      width: "100%",
      border: "1px solid #7484F9",
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#281c56",
      borderRadius: 5,
      flexDirection: "column",
      marginTop: 5,
      marginBottom: "auto",
    },
    mobileSubInner: {
      width: "100%",
      flexDirection: "row",
      display: "flex",
    },
    mobileSubImageContainer: {
      width: "25%",
      padding: 10,
    },
    mobileSubImageStyle: {
      maxHeight: "100%",
      objectFit: "contain",
      borderRadius: 5,
      border: "2px solid #3C4684",
    },
    mobileSubInfo: {
      height: "100%",
      width: "75%",
      display: "flex",
      flexDirection: "column",
      marginBottom: "auto",
      maxHeight: 200,
      alignItems: "flex-end",
    },
    mobileSubTitleContainer: {
      width: "100%",
      display: "flex",
      border: "0px solid yellow",
      flexDirection: "row",
      paddingTop: 5,
    },
    mobileSubInnerTitle: {
      width: "80%",
      border: "0px solid yellow",
      marginTop: 7,
      display: "flex",
      flexDirection: "row",
    },
    mobileSubBaseName: {
      lineHeight: 1.3,
      paddingBottom: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: "white",
      fontSize: 22,
    },
    mobileSubBaseNameText: {
      lineHeight: 1,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: "white",
      fontSize: 22,
    },
    mobileSubPlanIcon: {
      lineHeight: 1.3,
      padding: 3,
      paddingBottom: 2,
      borderRadius: 8,
      paddingLeft: 7,
      paddingRight: 7,
      border: "1px solid white",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: "white",
      fontSize: 10,
      marginTop: "auto",
    },
    mobileSubStatusContainer: {
      width: "15%",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
    },
    mobileSubStatusContainerInner: {
      width: 15,
      height: 15,
      borderRadius: 30,
      marginTop: "auto",
      border: '2px solid white'
    },
    mobileSubURLContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
    },
    mobileSubURLContainerInner: {
      width: "100%",
      paddingLeft: 0,
      paddingRight: 20,
      display: "flex",
      flexDirection: "column",
    },
    mobileSubURLText: {
      textAlign: "left",
      fontSize: 16,
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
    },
    mobileSubPlanDates: {
      width: "100%",
      marginBottom: 10,
      display: "flex",
      flexDirection: "column",
    },
    mobileSubPlanDatesInner: {
      width: "100%",
      paddingLeft: 0,
      paddingRight: 20,
      border: "0px solid yellow",
      display: "flex",
      flexDirection: "column",
    },
    mobileSubPlanDateText: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: "left",
      color: "#C6C6F2",
      fontSize: 14,
    },
  };

  return (
    <div style={styles.mobileSubListItem}>
      <div style={styles.mobileSubInner}>
        <div style={styles.mobileSubImageContainer}>
          <img
            src={subscription.stripeProduct.iconThumbnail.url}
            alt={subscription.stripeProduct.iconThumbnail.alt}
            style={styles.mobileSubImageStyle}
          />
        </div>
        <div style={styles.mobileSubInfo}>
          <div style={styles.mobileSubTitleContainer}>
            <div style={styles.mobileSubInnerTitle}>
              <p
                className="bolden-gate"
                style={styles.mobileSubBaseName}
              >{`${subscription.stripeProduct.baseName}`}</p>
              <p className="bolden-gate" style={styles.mobileSubBaseNameText}>
                &nbsp;
              </p>
              <div style={{ marginTop: 3 }}>
                <p
                  style={{
                    ...styles.mobileSubPlanIcon,
                    backgroundColor:
                      subscription.plan === "essentials"
                        ? "grey"
                        : subscription.plan === "pro"
                          ? "#6984ef"
                          : "goldenrod",
                  }}
                >{`${subscription.plan.toUpperCase()}`}</p>
              </div>
            </div>

            <div style={styles.mobileSubStatusContainer}>
              <div
                style={{
                  ...styles.mobileSubStatusContainerInner,
                  backgroundColor: subscription.active ? "#2AD640" : "red",
                }}
              />
              <p
                style={{ fontSize: 10, color: "white" }}
              >{`${subscription.active ? "ACTIVE" : "INACTIVE"}`}</p>
            </div>
            <div style={{ width: "5%" }} />
          </div>

          <div style={styles.mobileSubURLContainer}>
            <div style={styles.mobileSubURLContainerInner}>
              <p
                className="bolden-gate"
                style={{
                  ...styles.mobileSubURLText,
                  color: subscription.clientWebsiteURL
                    ? subscription.active
                      ? "#2AD640"
                      : "red"
                    : "#FFA500",
                }}
              >
                {subscription.clientWebsiteURL
                  ? subscription.clientWebsiteURL
                  : isMobile
                    ? "Tap here to configure"
                    : "Click here to configure"}
              </p>
            </div>
          </div>

          <div style={styles.mobileSubPlanDates}>
            <div style={styles.mobileSubPlanDatesInner}>
              <p
                style={styles.mobileSubPlanDateText}
              >{`Purchased: ${moment(subscription.purchaseDate).format("MMMM D yyyy")}`}</p>
              <p
                style={styles.mobileSubPlanDateText}
              >{`Renews: ${moment(subscription.renewalDate).format("MMMM D yyyy")}`}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionListItemMobile;
