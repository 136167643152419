// JJ-108
import React, { useState, useEffect } from "react";
import "../App.css";
import AnimatedTextButton from "./AnimatedTextButton";
import { FaBars } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import { BorderRight } from "@mui/icons-material";

function DashboardNavBar({
  handleToggleTheme,
  theme,
  handleMoveToScreen,
  handleGoToLoginScreen,
}) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900); // Adjust the threshold as needed
  const handleResize = () => {
    const isCurrentlyMobile = window.innerWidth < 900;
    setIsMobile(isCurrentlyMobile);

    if (drawerOpen && !isCurrentlyMobile) {
      setDrawerOpen(false);
    }
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [drawerOpen]);

  const handleLoginPress = () => {
    toggleDrawer();
    handleGoToLoginScreen();
  };

  let styles = {
    logoutButton: {
      backgroundColor: " #6e6ef4",
    },
    desktopContainer: {
      border: "0px solid red",
      flexDirection: "row",
      width: "60%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    mobileContainer: {
      border: "0px solid red",
      flexDirection: "row",
      width: "85%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    hamburger: {
      color: "white",
      marginRight: "auto",
      fontSize: 30,
    },
  };

  return (
    <div className="dashboard-navbar-container">
      <div className={`drawer ${drawerOpen ? "open" : ""}`}>
        <div className="drawer-header">
          <button
            style={{ marginLeft: 20 }}
            className="close-button"
            onClick={toggleDrawer}
          >
            <FaArrowLeft size={30} />
          </button>
        </div>
        <div className="drawer-content">
          <AnimatedTextButton
            text={"Account"}
            onClick={() => handleMoveToScreen("AccountEditScreen")}
            theme={theme}
          />
          <AnimatedTextButton
            text={"Support"}
            onClick={() => handleMoveToScreen("SupportScreen")}
            theme={theme}
          />
          <AnimatedTextButton
            text={"Log out"}
            onClick={() => handleLoginPress()}
            theme={theme}
            style={styles.logoutButton}
            className={"bolden-gate"}
          />
        </div>
      </div>

      {!isMobile && (
        <>
          <div style={styles.desktopContainer}>
            <AnimatedTextButton
              text={"Account"}
              onClick={() => handleMoveToScreen("AccountEditScreen")}
              theme={theme}
            />
            <AnimatedTextButton
              text={"Support"}
              onClick={() => handleMoveToScreen("SupportScreen")}
              theme={theme}
            />
          </div>
        </>
      )}

      {isMobile && (
        <div style={styles.mobileContainer}>
          <button style={styles.hamburger} onClick={toggleDrawer}>
            {<FaBars />}
          </button>
        </div>
      )}
    </div>
  );
}

export default DashboardNavBar;
